// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_ETIMS_URL = process.env.VUE_APP_ETIMS_URL;

class EtimsService {
    initialize(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}initializer/selectInitInfo`, item, {
            headers: authHeader(),
        });
    }

    getcodelist(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}code/selectCodes`, item, {
            headers: authHeader(),
        });
    }

    saveItems(item, config) {
        const etimsData = {
            tin: item.tin,
            bhfId: item.bhfId,
            itemClsCd: item.itemClsCd,
            itemCd: item.itemCd,
            itemTyCd: item.itemTyCd,
            itemNm: item.itemNm,
            orgnNatCd: item.orgnNatCd,
            pkgUnitCd: item.pkgUnitCd,
            qtyUnitCd: item.qtyUnitCd,
            taxTyCd: item.taxTyCd,
            dftPrc: item.dftPrc,
            isrcAplcbYn: item.isrcAplcbYn,
            useYn: item.useYn,
            regrNm: item.regrNm,
            regrId: item.regrId,
            modrNm: item.modrNm,
            modrId: item.modrId,
        };     
        return axios.post(`${VUE_APP_ETIMS_URL}items/saveItems`, etimsData, config);
    }

    //recheck this call from docs
    selectItems(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}items/selectItems?tin=` + item.tin + '&bhfid=' + item.bhfId, item, {
            headers: authHeader(),
        });
    }

    saveItemComposition(item){
        return axios.post(`${VUE_APP_ETIMS_URL}items/saveItemComposition`, item, {
            headers: authHeader(),
        });
    }

    eTimsSale(formData) {
        return axios.post(`${VUE_APP_ETIMS_URL}trnsSales/saveSales`, formData, {
            headers: authHeader(),
        });
    }


    //other etims api calls
    selectItemsClass(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}itemClass/selectItemsClass`, item, {
            headers: authHeader(),
        });
    }

    selectCustomer(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}customers/selectCustomer`, item, {
            headers: authHeader(),
        });
    }

    selectBranches(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}branches/selectBranches`, item, {
            headers: authHeader(),
        });
    }
    
    selectNotices(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}notices/selectNotices`, item, {
            headers: authHeader(),
        });
    }

    saveBrancheCustomers(item){
        return axios.post(`${VUE_APP_ETIMS_URL}branches/saveBrancheCustomers`, item, {
            headers: authHeader(),
        });
    }

    saveBrancheUsers(item){
        return axios.post(`${VUE_APP_ETIMS_URL}branches/saveBrancheUsers`, item, {
            headers: authHeader(),
        });
    }

    saveBrancheInsurances(item){
        return axios.post(`${VUE_APP_ETIMS_URL}branches/saveBrancheInsurances`, item, {
            headers: authHeader(),
        });
    }

    selectImportItems(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}imports/selectImportItems`, item, {
            headers: authHeader(),
        });
    }

    updateImportItems(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}imports/updateImportItems`, item, {
            headers: authHeader(),
        });
    }

    selectTrnsPurchaseSales(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}trnsPurchase/selectTrnsPurchaseSales`, item, {
            headers: authHeader(),
        });
    }

    savePurchases(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}trnsPurchase/savePurchases`, item, {
            headers: authHeader(),
        });
    }

    saveStockMaster(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}stockMaster/saveStockMaster`, item, {
            headers: authHeader(),
        });
    }

    selectStockItems(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}stock/selectStockItems`, item, {
            headers: authHeader(),
        });
    }

    saveStockItems(item) {
        return axios.post(`${VUE_APP_ETIMS_URL}saveStockItems/saveStockItems`, item, {
            headers: authHeader(),
        });
    }
}

export default new EtimsService()