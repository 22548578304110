import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class MenuGroupService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'menu-group', { headers: authHeader() });
    }
    store(formData) {
        return axios.post(VUE_APP_BASE_URL + 'menu-group', formData, { headers: authHeader() });
    }
    storeSingle(formData) {
        return axios.post(VUE_APP_BASE_URL + 'menu-group-store', formData, { headers: authHeader() });
    }
    update(formData) {
        return axios.put(VUE_APP_BASE_URL + 'menu-group/' + formData.id, formData, { headers: authHeader() });
    }
    delete(item) {
        return axios.delete(VUE_APP_BASE_URL + 'menu-group/' + item.id, { headers: authHeader() });
    }
}

export default new MenuGroupService()