// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class RoleService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'role', { headers: authHeader() })
    }
    store(user) {
        return axios.post(VUE_APP_BASE_URL + 'role', user, { headers: authHeader() })
    }
    update(user) {
        return axios.put(VUE_APP_BASE_URL + 'role/' + user.id, user, { headers: authHeader() })
    }
    delete(user) {
        return axios.delete(VUE_APP_BASE_URL + 'role/' + user.id, { headers: authHeader() })
    }
}

export default new RoleService()