<template>
  <div>
    <div class="row mb-2">
      <div class="">
        <div class="card bg-100 shadow-none border">
          <div class="row gx-0 flex-between-center">
            <div class="col-sm-auto d-flex align-items-center">
              <img class="ms-n2" src="assets/img/illustrations/crm-bar-chart.png" alt="" width="90" />
              <div>
                <h6 class="text-primary fs--1 mb-0">Bill</h6>
                <h4 class="text-warning fw-bold mb-0">Management</h4>
              </div>
              <img class="ms-n4 d-md-none d-lg-block" src="assets/img/illustrations/crm-line-chart.png" alt=""
                width="150" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <div class="row g-0">
          <div class="col-12 mb-3">
            <div class="row justify-content-center justify-content-sm-between">
              <div class="col-sm-auto text-center">
                <h5 class="d-inline-block">Billed Monthly</h5>
                <span class="badge badge-soft-success rounded-pill ms-2">Save 25%</span>
              </div>
              <div class="col-sm-auto d-flex justify-content-center mt-1 mt-sm-0">
                <!-- <label class="form-check-label me-2" for="customSwitch1">Monthly</label>
                <div class="form-check form-switch mb-0">
                  <input
                    class="form-check-input"
                    id="customSwitch1"
                    type="checkbox"
                    checked="checked"
                  /><label class="form-check-label align-top" for="customSwitch1"
                    >Yearly</label
                  >
                </div> -->
              </div>
            </div>
          </div>
          <div class="row border-top">
            <div class="row pt-2 ps-3">
              <div class="col-6">
                <h5>1. M-PESA Express</h5>
                <div v-if="errorMessage" class="alert alert-danger my-2 mx-3" role="alert">
                  <p>
                    {{ errorMessage }}
                  </p>
                </div>
                <div v-if="successMessage" class="alert alert-success my-2 mx-3" role="alert">
                  <p>
                    Your payment was received successfully, please logout for better experience.
                  </p>
                </div>
                <div class="alert alert-info my-2" role="alert">
                  <p>
                    Click on "Send Prompts" button to generate a payment request on
                    your phone.
                  </p>
                </div>
                <div class="row d-flex mb-2">
                  <div class="col-4">
                    Amount (Ksh)
                  </div>
                  <div class="col-4">
                    <input class="form-control fw-bold fs-bold" placeholder="e.g 3500" aria-label="search"
                      v-model="formData.price" :class="v$.formData.price.$dirty && v$.formData.price.$invalid
                        ? `is-invalid`
                        : v$.formData.price.$dirty
                          ? `is-valid`
                          : ``
                        " />
                  </div>
                  <div class="col-4">
                    <button class="btn btn-sm btn-success" @click="handlePay">Send Prompt</button>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row pt-2 ps-3">
                  <h5>2. Offline Payment</h5>
                  <div class="alert alert-info" role="alert">
                    <p>
                      Use M-PESA PayBill Number <span class="fw-bold fs-bold">4114541</span> and Account No. <span
                        class="fw-bold fs-bold">{{ user.company.unique_id }}</span> to top up your account.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h5 class="d-inline-block mt-4">Monthly Packages</h5>
          <div class="col-lg-4 border-top border-bottom">
            <div class="h-100">
              <div class="text-center p-4">
                <h3 class="fw-normal my-0">Basic</h3>
                <p class="mt-3">
                  For individuals and businesses that need to just the basic features of Bulla.
                </p>
                <h2 class="fw-medium my-4">
                  <sup class="fw-normal fs-2 me-1">KES.</sup>2,999<small class="fs--1 text-700">/ Month</small>
                </h2>
                <a class="btn" :class="user.company.plan=='basic' ? 'btn-success disabled': 'btn-outline-success' "> {{user.company.plan=='basic' ? 'Your current plan' : 'Select Plan'}}</a>
              </div>
              <hr class="border-bottom-0 m-0" />
              <div class="text-start px-sm-4 py-4">
                <h5 class="fw-medium fs-0">Manage your Business with:</h5>
                <ul class="list-unstyled mt-3">
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> 5 Users
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> 5 Locations
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> SMS Marketing @ KES 1/SMS
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Monthly Updates
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Daily SMS Reporting
                    <div class="badge badge-soft-success rounded-pill">New</div>
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Daily Email Reporting
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> 1,000 Customers & Suppliers
                  </li>
                </ul>
                <!-- <a class="btn btn-link" href="pricing-default.html#">More about Single</a> -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 border-top border-bottom dark__bg-1000 px-4 px-lg-0"
            style="background-color: rgba(115, 255, 236, 0.18)">
            <div class="h-100">
              <div class="text-center p-4">
                <h3 class="fw-normal my-0">Business</h3>
                <p class="mt-3">
                  For teams and COMPANIES that need to manage work across initiatives.
                </p>
                <h2 class="fw-medium my-4">
                  <sup class="fw-normal fs-2 me-1">KES.</sup>5,999<small class="fs--1 text-700">/ Month</small>
                </h2>
                <a class="btn" :class="user.company.plan=='business' ? 'btn-success disabled': 'btn-outline-success' "> {{user.company.plan=='business' ? 'Your current plan' : 'Upgrade to Business'}}</a>
              </div>
              <hr class="border-bottom-0 m-0" />
              <div class="text-start px-sm-4 py-4">
                <h5 class="fw-medium fs-0">Everything in Starter, plus:</h5>
                <ul class="list-unstyled mt-3">
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Online Store
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Purchase Orders
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Invoices
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Quotations
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Inventory
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Unlimited Users
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> SMS Marketing @ KES 0.9/SMS
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Unlimited Customers & Suppliers
                  </li>
                </ul>
                <!-- <a class="btn btn-link" href="pricing-default.html#">More about Business</a> -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 border-top border-bottom">
            <div class="h-100">
              <div class="text-center p-4">
                <h3 class="fw-normal my-0">Extended</h3>
                <p class="mt-3">
                  For organizations that need dedicated server, additional security, and support.
                </p>
                <h2 class="fw-medium my-4">
                  <sup class="fw-normal fs-2 me-1">KES.</sup>9,999<small class="fs--1 text-700">/ Month</small>
                </h2>
                <a class="btn" :class="user.company.plan=='extended' ? 'btn-success disabled': 'btn-outline-success' "> {{user.company.plan=='extended' ? 'Your current plan' : 'Upgrade to Extended'}}</a>
              </div>
              <hr class="border-bottom-0 m-0" />
              <div class="text-start px-sm-4 py-4">
                <h5 class="fw-medium fs-0">Everything in Business, plus:</h5>
                <ul class="list-unstyled mt-3">
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> eCommerce Site
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Daily Shifts
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Human Resource Manager
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Communication Module
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Any other custom module
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Mpesa Integration
                    <div class="badge badge-soft-primary rounded-pill">Coming soon</div>
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Customer Self Service
                    <div class="badge badge-soft-primary rounded-pill">Coming soon</div>
                  </li>
                  <li class="py-1">
                    <span class="me-2 fas fa-check text-success"> </span> Production System
                    <div class="badge badge-soft-primary rounded-pill">Coming soon</div>
                  </li>
                </ul>
                <a class="btn btn-link" href="https://bulla.africa">More about Extended</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import CartService from "../services/cart.service";
import TablesService from "../services/tables.service";
import TicketService from "../services/ticket.service";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import CustomerService from "../services/customer.service";
import MenuItemsService from "../services/menu_item.service";
import MenuGroupService from "../services/menu_group.service";
import OrderTypeService from "../services/order_types.service";
import MenuCategoryService from "../services/menu_category.service";
import MenuItemSizeService from "../services/menu_item_size.service";

export default {
  mixins: [mixins],
  validations() {
    return {
      formData: {
        price: { required },
      },
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
  },
  data() {
    return {
      can_retry: true,
      retrying: false,
      errorMessage: false,
      successMessage: false,
      stk_sent: false,
      transaction_complete: false,
      payment_form: false,
      location_quantities: [],
      selected_order_types: [],
      menu_item_sizes: [],
      add_group: false,
      add_category: false,
      add_location: false,
      checkout: false,
      daily_analysis: [],
      weekly_analysis: [],
      formData: {
        group_id: "",
        active: 0,
        visible: 1,
        unit_name: "",
        order_types: [],
      },
      searchKey: "",
      barcode: "",
      loading_qr: false,
      dialog: false,
      items: {},
      tables: [],
      order_types: [],
      dialogQty: false,
      itemDialog: false,
      productCategories: [],
      customers: [],
      groups: [],
      selectedProductCategory: "",
      cart: {},
      table_selection: localStorage.getItem("table") == null ? true : false,
      category_selection: false,
      order_type_selection: true,
      order_type:
        localStorage.getItem("order_type") == null
          ? {}
          : JSON.parse(localStorage.getItem("order_type")),
      selectedCategory: 0,
      selected_category: "",
      selected_category_data: {},
      selected_group: "",
      selected_table: "",
      selected_customer: "",
    };
  },
  watch: {
    selected_group(newVal, oldVal) {
      this.formData.GROUP_ID = newVal;
    },
  },
  async created() {
    // this.forceReload();
  },
  computed: {
    totalValue() {
      let itemArray = Object.values(this.cart);
      let totalValue = 0;
      itemArray.forEach((element) => {
        totalValue =
          totalValue + parseFloat(element.quantity) * parseFloat(element.price);
      });
      return parseFloat(totalValue);
    },
    cartLength() {
      let data = this.cart;
      if (data.length) {
        return data.length;
      } else {
        return "empty";
      }
    },
    cartItems() {
      let cartData = Object.values(this.cart);
      return cartData;
    },
    table() {
      return localStorage.getItem("table") == null ? {} : this.selected_table;
    },
    category() {
      return this.selected_category_data;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    receivePayment() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.formData.amount = this.cart;
      this.formData.company_id = this.user.company_id;
      this.formData.company_name = this.user.company.name;
      this.formData.company_phone = this.user.company.telephone;
      this.formData.reference = this.user.company.unique_id;
      TicketService.registerPayment(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
          } else {
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async checkStk() {
      this.stk_sent = true;
      this.$store.commit("SET_LOADING", true);
      let user = JSON.parse(localStorage.getItem("user"));
      var params2 = new FormData();
      params2.append("CheckoutRequestID", this.CheckoutRequestID);
      params2.append("BillRefNumber", user.company.unique_id);
      params2.append("TransAmount", this.formData.price);

      for (let i = 0; i < 5; i++) {
        if (this.transaction_complete) {
          this.stk_sent = false;
          this.$store.commit("SET_LOADING", false);
          this.can_retry = true;
          if (!this.errorMessage) {
            this.successMessage = true;
          }
          break;
        } else {
          await axios
            .post("https://api.bulla.africa/stk-query.php", params2)
            .then((response) => {
              if (response.data.ResponseCode == 0 && response.data.ResultCode == 0) {
                toast.success(response.data.ResultDesc);
                this.transaction_complete = true;
                this.$store.commit("SET_LOADING", false);
                this.receivePayment();
              } else if (
                typeof response.data.errorCode != undefined &&
                response.data.errorMessage == "The transaction is being processed"
              ) {
                toast.info(response.data.errorMessage);
              }
              // Insufficient bal
              else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultDesc ==
                "The balance is insufficient for the transaction."
              ) {
                toast.error(response.data.ResultDesc);
                this.errorMessage = response.data.ResultDesc;
                this.$store.commit("SET_LOADING", false);
              }
              // DS not reached
              else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultCode == 1037
              ) {
                toast.error(response.data.ResultDesc);
                this.transaction_complete = true;
                this.errorMessage = response.data.ResultDesc;
                this.$store.commit("SET_LOADING", false);
              }
              // DS cancelled
              else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultCode == 1032
              ) {
                toast.error(response.data.ResultDesc);
                this.transaction_complete = true;
                this.errorMessage = response.data.ResultDesc;
                this.$store.commit("SET_LOADING", false);
              } else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultCode == 2001
              ) {
                this.transaction_complete = true;
                this.$store.commit("SET_LOADING", false);
              } else {
                toast.info(response.data.ResultDesc);
              }
            });
          await this.sleep(10000);
        }
      }
    },
    async handlePay() {
      this.v$.formData.$touch();
      if (this.v$.formData.$invalid) {
        return;
      } else {
        this.$store.commit("SET_LOADING", true);
        this.retrying = true;
        this.can_retry = false;
        this.errorMessage = false;
        this.successMessage = false;
        this.transaction_complete = false;
        let formData = new FormData();
        // formData.append("amount", this.selectedPlan.price);
        let user = JSON.parse(localStorage.getItem("user"));
        formData.append("amount", this.formData.price);
        formData.append("phone_number", "254" + parseInt(user.company.telephone.replace(/\+/g, "")));
        formData.append("account_reference", user.company.unique_id);
        this.pushSTK(formData);
      }
    },
    async pushSTK(params) {
      // format phone
      this.$store.commit("SET_LOADING", true);
      await axios
        .post("https://api.bulla.africa/stk-push.php", params)
        .then((response) => {
          console.log(response.data);
          if (response.data.errorMessage || response.data.errorCode) {
            toast.error(response.data.errorMessage);
            this.stk_sent = false;
            this.can_retry = true;
            this.$store.commit("SET_LOADING", false);
          } else if (response.data.ResultDesc == "The balance is insufficient for the transaction."
          ) {
            toast.error(response.data.ResultDesc);
            this.stk_sent = false;
            this.can_retry = true;
            this.$store.commit("SET_LOADING", false);
          } else if (response.data.ResultDesc == "Rule limited."
          ) {
            toast.error(response.data.ResultDesc);
            this.stk_sent = false;
            this.can_retry = true;
            this.$store.commit("SET_LOADING", false);
          }
          else {
            this.CheckoutRequestID = response.data.CheckoutRequestID;
            toast.success("PIN prompt sent to your phone!");
            this.can_retry = true;
            this.checkStk();
          }
        });
    },
    addLocationQty(e, location) {
      let data = {
        quantity: parseInt(e.target.value),
        location_id: location.id,
      };
      // exist
      let exist = this.location_quantities.find((el) => el.location_id == location.id);
      if (exist) {
        this.location_quantities.map((el) => {
          if (el.location_id == location.id) {
            el.quantity = parseInt(e.target.value);
          }
        });
      } else {
        this.location_quantities.push(data);
      }
      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    addToLocations(location) {
      if (this.formData.order_types && this.formData.order_types.includes(location.id)) {
        this.formData.order_types = this.formData.order_types.filter(
          (el) => el != location.id
        );
        this.selected_order_types = this.selected_order_types.filter(
          (el) => el.id != location.id
        );
        this.location_quantities = this.location_quantities.filter(
          (el) => el.location_id != location.id
        );
      } else {
        this.formData.order_types.push(location.id);
        this.selected_order_types.push(location);
      }

      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    toggleActive() {
      this.formData.visible = !this.formData.visible;
    },
    toggleVAT() {
      this.formData.vat = !this.formData.vat;
    },
    toggleSellable() {
      this.formData.sellable = !this.formData.sellable;
    },
    addToLocations(location) {
      if (this.formData.order_types && this.formData.order_types.includes(location.id)) {
        this.formData.order_types = this.formData.order_types.filter(
          (el) => el != location.id
        );
        this.selected_order_types = this.selected_order_types.filter(
          (el) => el.id != location.id
        );
        this.location_quantities = this.location_quantities.filter(
          (el) => el.location_id != location.id
        );
      } else {
        this.formData.order_types.push(location.id);
        this.selected_order_types.push(location);
      }

      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    addToItems(type_id) {
      if (this.formData.order_types && this.formData.order_types.includes(type_id)) {
        this.formData.order_types = this.formData.order_types.filter(
          (el) => el != type_id
        );
      } else {
        this.formData.order_types
          ? this.formData.order_types.push(type_id)
          : (this.formData.order_types = [type_id]);
      }
    },
    saveResource() {
      this.v$.formData.name.$touch();
      this.v$.formData.price.$touch();
      this.v$.formData.group_id.$touch();
      this.v$.formData.buy_price.$touch();
      this.v$.formData.stock_amount.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      if (this.file) {
        formData.append("file", this.file);
      }
      let arr = [];
      arr.push(this.order_type.id);
      formData.append("order_types", arr);
      formData.append("group_id", this.selected_group);
      formData.append("name", this.formData.name);
      formData.append("price", this.formData.price);
      formData.append("ws_price", this.formData.ws_price);
      formData.append("barcode", this.formData.barcode);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("description", this.formData.description);
      formData.append("stock_amount", this.formData.stock_amount);
      formData.append("vat", this.formData.vat ? 1 : 0);
      formData.append("sellable", this.formData.sellable ? 1 : 0);
      formData.append("visible", this.formData.visible ? 1 : 0);
      formData.append("location_quantities", JSON.stringify(this.location_quantities));

      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      MenuItemsService.store(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getMenuItemsByGroup(this.selected_group);
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleCategory(val) {
      this.add_category = val;
      if (val) {
        setTimeout(() => {
          this.$refs.add_category_key.focus();
        }, 400);
      }
    },
    addCategory() {
      this.add_category = false;
      let data = {
        name: this.formData.category_name,
        order_type_id: this.order_type.id,
      };
      MenuCategoryService.storeSingle(data).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Category created!");
            this.formData = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
          }
          this.getMenuCategoryByOrder();
          this.$store.commit("SET_LOADING", true);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          toast.error("The name already exist!");
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    toggleGroup(val) {
      this.add_group = val;
      if (val) {
        setTimeout(() => {
          this.$refs.add_group_key.focus();
        }, 400);
      }
    },
    toggleLocation(val) {
      this.add_location = val;
      if (val) {
        setTimeout(() => {
          this.$refs.add_location_key.focus();
        }, 400);
      }
    },
    addGroup() {
      if (this.selected_category == "") {
        toast.info("Please create/select categories first and select one!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.add_group = false;
      let data = {
        name: this.formData.group_name,
        order_type_id: this.order_type.id,
        category_id: this.selected_category,
      };
      MenuGroupService.storeSingle(data).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Group created!");
            this.formData = {};
            // get groups
            this.getMenuGroupsByCategory();
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
          }
          this.getMenuGroupsByOrder();
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
          toast.error("The name already exist!");
        }
      );
    },
    addLocations() {
      this.$store.commit("SET_LOADING", true);
      this.add_location = false;
      let data = {
        name: this.formData.location_name,
      };
      OrderTypeService.storeSingle(data).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Location created!");
            this.formData = {};
            this.getAllOrderTypes();
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
          toast.error("The name already exist!");
        }
      );
    },
    deleteItem(item) {
      this.cart.splice(this.cart.indexOf(item), 1);
    },
    editItem(item, quantity) {
      if (quantity == 0) {
        this.cart.splice(this.cart.indexOf(item), 1);
      }
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.id == item.id);
        existingElement.quantity = quantity;
        existingElement.sub_total = quantity * item.price;
      }
      this.cart = myCart;
      toast.success("1 " + item.NAME + " updated in cart");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    toggleCheckout(val) {
      this.checkout = val;
    },
    formatToCurrency: function (amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    tenderedInput() {
      // this.formData.balance_amount =  this.formData.tender_amount;
      this.formData.balance_amount =
        parseFloat(this.formData.tender_amount) - parseFloat(this.totalValue);
    },
    payMpesa() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "MPESA";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCash() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CASH";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    completeCheckout() {
      this.preview_cash_modal = false;
      this.preview_dc_modal = false;
      this.digital_change = false;
      this.change_sent = false;
      this.qrData = {};
      this.clearCart();
      this.$notify({
        group: "foo",
        type: "success",
        title: "Checkout complete",
        text: "order places and paid successfuly!",
      });
    },
    getGroups(cat) {
      console.log(cat);
      this.selected_category_data = cat;
      this.selected_category = cat.id;
      if (this.selected_category == null) {
        this.getItemsByOrder(this.order_type);
      } else {
        this.getMenuGroupsByCategory();
      }
      // this.getMenuItemsByGroup(this.groups[0].id);
    },
    getMenuGroupsByCategory() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        menu_category: this.selected_category,
      };
      return ProductService.getMenuGroupsByCategory(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            console.log(response.data.data);
            this.selected_group = response.data.data.at(0)
              ? response.data.data.at(0).id
              : "";
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    filterItems() {
      this.searchKey = this.searchKey.toLowerCase();
      if (this.searchKey) {
        let array = Object.values(this.items);
        this.items = array.filter((item) => {
          let name = item.name.toLowerCase();
          return name.includes(this.searchKey);
        });
      } else {
        this.getAll();
      }
    },
    orderTicket() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Order placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    clearCart() {
      this.cart = {};
      toast.info("Cart cleared!");
    },
    goToMyOrders() {
      this.$router.push("/my-orders");
    },
    goToAllOrders() {
      this.$router.push("/pos-open-order");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    getItemsByOrder(item) {
      return CartService.byOrder(item.id).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAll() {
      return CartService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuItemsByGroup(id) {
      this.$store.commit("SET_LOADING", true);
      this.selected_group = id;
      let formData = {
        menu_group_id: this.selected_group,
      };
      return ProductService.getMenuItemsByGroup(formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllTables() {
      return TablesService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.tables = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuGroupsByOrder() {
      let formData = {
        order_type: this.order_type.id,
      };
      return ProductService.getMenuGroupsByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategoryByOrder() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        order_type: this.order_type.id,
      };
      return ProductService.getMenuCategoryByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getCustomers() {
      // samdoh
      return CustomerService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.customers = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllProducts() {
      return ProductService.getAll().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllGroups() {
      return ProductService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    selectTable(item) {
      this.table_selection = false;
      localStorage.setItem("table", JSON.stringify(item));
      this.$notify({
        group: "foo",
        type: "info",
        title: "Selection",
        text: "Table selected!",
      });
    },
    async selectOrderType(item) {
      this.order_type = item;
      this.table_selection = true;
      this.order_type_selection = false;
      localStorage.setItem("order_type", JSON.stringify(item));
      await this.getItemsByOrder(item.id);
      await this.getMenuCategoryByOrder();
      this.selected_category = this.productCategories.at(0).id;
      if (this.productCategories.length > 0) {
        await this.getMenuGroupsByCategory(this.productCategories.at(0).id);
        if (this.groups.at(0)) {
          await this.getMenuItemsByGroup(this.groups.at(0).id);
        } else {
          this.items = [];
        }
      } else {
        this.groups = [];
        this.selected_group = "";
        this.items = [];
      }
    },
  },
};
</script>
