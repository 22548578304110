<template>
  <main class="main">
    <div class="container" data-layout="container">
      <slot />
    </div>
  </main>
</template>
<script>
export default {};
</script>
