<template>
  <div>
    <div class="row g-3 mb-3">
      <div class="col-12 col-xl-12">
        <div class="row g-3">
          <div class="col-12">
            <div class="card bg-transparent-50 overflow-hidden">
              <div class="card-header position-relative">
                <div
                  class="bg-holder d-none d-md-block bg-card z-index-1"
                  style="
                    background-image: url('../assets/img/illustrations/ecommerce-bg.png');
                    background-size: 230px;
                    background-position: right bottom;
                    z-index: -1;
                  "
                ></div>
                <!--/.bg-holder-->
                <div class="position-relative z-index-2">
                  <div>
                    <h5 class="text-primary">
                      {{ getTimegreetings() }}, {{ user.first_name }}!
                    </h5>
                    <p>Configure your store here!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 mx-2">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="mb-0">Website Settings</h5>
                </div>
                <div class="col-auto">
                  <!-- <a
                    class="btn btn-falcon-default btn-sm"
                    href="customer-details.html#!"
                    ><span class="fas fa-pencil-alt fs--2 me-1"></span>Update
                    details</a
                  > -->
                </div>
              </div>
            </div>
            <div class="card-body bg-light border-top">
              <div class="row">
                <div class="col-lg col-xxl-5">
                  <h6 class="fw-semi-bold ls mb-3 text-uppercase">
                    Business Settings
                  </h6>
                  <hr />
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Business Name</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. Electrical engineering"
                        v-model="formData.title"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Body</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. Get the Energy"
                        v-model="formData.body"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Action Text</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. Get a Quote"
                        v-model="formData.actionText"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Email</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. muli@savvyenergy.co.ke"
                        v-model="formData.email"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Contact Us Text</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. Contact"
                        v-model="formData.contactUsText"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Our Services</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. Services"
                        v-model="formData.ourServicesText"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Blog</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. Blogs"
                        v-model="formData.blogText"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Phone</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. 070xxxxxxx"
                        v-model="formData.phone"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Address 1</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. Professional Centre, Room 2, Ground Floor, Ngong road"
                        v-model="formData.address1"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Address 2</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. P.O BOX: 1607-90100 MACHAKOS"
                        v-model="formData.address2"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Address 3</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder="e.g. address3"
                        v-model="formData.address3"
                      />
                    </div>
                  </div>
                </div>
                <!-- ------------------- -->

                <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <h6 class="fw-semi-bold ls mb-3 text-uppercase">
                    URL Information
                  </h6>
                  <hr />

                  <div class="row mb-3">
                    <div class="col-12 text-center">
                      <div
                        class="mt-n7 mb-7 position-relative avatar avatar-5xl avatar-profile shadow-sm img-thumbnail rounded-circle"
                      >
                        <div
                          class="h-100 w-100 rounded-circle overflow-hidden position-relative"
                        >
                          <img
                            :src="formData.logo"
                            width="300"
                            alt=""
                            data-dz-thumbnail="data-dz-thumbnail"
                          /><input
                            @change="onFileChange"
                            class="d-none"
                            id="profile-image"
                            type="file"
                          /><label
                            class="mb-0 overlay-icon d-flex flex-center"
                            for="profile-image"
                            ><span class="bg-holder overlay overlay-0"></span
                            ><span
                              class="z-index-1 text-white dark__text-white text-center fs--1"
                              ><span class="fas fa-camera"></span
                              ><span class="d-block">Update</span></span
                            ></label
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="col-12 col-sm-12 mt-1">
                    <div class="row mb-3">
                      <div class="col-4 col-sm-4 mt-1">
                        <p class="fw-semi-bold mb-1">Get a Quote Url</p>
                      </div>
                      <div class="col">
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          type="text"
                          placeholder="e.g. index.html#getAQuote"
                          v-model="formData.actionUrl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 mt-1">
                    <div class="row mb-3">
                      <div class="col-4 col-sm-4 mt-1">
                        <p class="fw-semi-bold mb-1">Blog Url</p>
                      </div>
                      <div class="col">
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          type="text"
                          placeholder="e.g. blogs.html"
                          v-model="formData.blogUrl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 mt-1">
                    <div class="row mb-3">
                      <div class="col-4 col-sm-4 mt-1">
                        <p class="fw-semi-bold mb-1">Twitter Url</p>
                      </div>
                      <div class="col">
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          type="text"
                          placeholder="e.g. muli@savvyenergy.co.ke"
                          v-model="formData.twitterUrl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 mt-1">
                    <div class="row mb-3">
                      <div class="col-4 col-sm-4 mt-1">
                        <p class="fw-semi-bold mb-1">Instagram Url</p>
                      </div>
                      <div class="col">
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          type="text"
                          placeholder="e.g. https://twitter.com/i/flow/login?redirect_after_login=%2Fsavvy_ltd"
                          v-model="formData.instagramUrl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 mt-1">
                    <div class="row mb-3">
                      <div class="col-4 col-sm-4 mt-1">
                        <p class="fw-semi-bold mb-1">Linkedin Url</p>
                      </div>
                      <div class="col">
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          type="text"
                          placeholder="e.g. https://www.linkedin.com/"
                          v-model="formData.linkedinUrl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 mt-1">
                    <div class="row mb-3">
                      <div class="col-4 col-sm-4 mt-1">
                        <p class="fw-semi-bold mb-1">Facebook Url</p>
                      </div>
                      <div class="col">
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          type="text"
                          placeholder="e.g. https://www.facebook.com/savvyCoLtd/?_rdc=1&_rdr"
                          v-model="formData.facebookUrl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 mt-1">
                    <div class="row mb-3">
                      <div class="col-4 col-sm-4 mt-1">
                        <p class="fw-semi-bold mb-1">Contact Us Url</p>
                      </div>
                      <div class="col">
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          type="text"
                          placeholder="e.g. contact.html"
                          v-model="formData.contactUsUrl"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 mt-1">
                    <div class="row mb-3">
                      <div class="col-4 col-sm-4 mt-1">
                        <p class="fw-semi-bold mb-1">Our Services Url</p>
                      </div>
                      <div class="col">
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          type="text"
                          placeholder="e.g. services.html"
                          v-model="formData.ourServicesUrl"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer border-top text-end">
              <!-- <a
                class="btn btn-falcon-default btn-sm"
                href="customer-details.html#!"
                ><span class="fas fa-dollar-sign fs--2 me-1"></span>Refund</a
              > -->
              <button
                class="btn btn-falcon-success btn-sm ms-2"
                @click="updateConfigaration"
              >
                <span class="fas fa-save fs--2 me-1"></span>Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import SimpleTypeahead from "vue3-simple-typeahead";
import FooterComp from "@/components/FooterComp.vue";
import ECommerceService from "../services/e-commerce.service";
import { required, email, minLength } from "@vuelidate/validators";

// const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "InventoryDashboardView",
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  data() {
    return {
      file: "",
      searchValue: "",
      modals_key: 1,
      refreshed: 0,
      formData: {},
    };
  },
  validations() {
    return {
      formData: {
        size_id: { required },
        product_id: { required },
        quantity: { required },
        user_id: { required },
      },
    };
  },

  created() {
    this.getWebisiteCofig();
  },
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },

  methods: {
    async onFileChange(e) {
      console.log(e.target.files[0]);
      this.filename = "Selected File: " + e.target.files[0].name;
      this.file = e.target.files[0];
      await this.updateLogo();
      this.updateConfigaration();
    },
    updateLogo() {
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("id", this.formData.id);
      formData.append("file", this.file);
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      ECommerceService.updateLogo(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAbsoluteUrl(relativePath) {
      // Assuming your backend returns a complete URL, otherwise, you may need to adjust this logic
      return `${window.location.origin}${relativePath}`;
    },

    // .......starts------------
    updateConfigaration() {
      this.$store.commit("SET_LOADING", true);
      this.formData.company_id = this.user.company_id;
      console.log(" I thought this is the id", this.formData.id);
      ECommerceService.configureWebsite(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getWebisiteCofig();
          } else {
            toast.error(
              response.data.error ||
                response.data.errors.at(0) ||
                response.data.message
            );
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          toast.error(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    getWebisiteCofig() {
      this.type = "open";
      this.records = 0;
      this.per_page = 1;
      this.current_page = 0;
      this.$store.commit("SET_LOADING", true);
      this.$store
        .dispatch("e_commerce/getWebisiteCofig", {
          size: this.per_page,
          page: this.current_page,
        })
        .then(
          (response) => {
            if (response.status === 200 && response?.data) {
              const { attributes } = response.data;

              this.formData = {
                id: response.data.id,
                title: attributes.title,
                logo: process.env.VUE_APP_API_URL + attributes.logo,
                company_id: attributes.company_id,
                body: attributes.body,
                actionText: attributes.actionText,
                actionUrl: attributes.actionUrl,
                email: attributes.email,
                phone: attributes.phone,
                twitterUrl: attributes.twitterUrl,
                instagramUrl: attributes.instagramUrl,
                linkedinUrl: attributes.linkedinUrl,
                facebookUrl: attributes.facebookUrl,
                contactUsText: attributes.contactUsText,
                contactUsUrl: attributes.contactUsUrl,
                ourServicesText: attributes.ourServicesText,
                ourServicesUrl: attributes.ourServicesUrl,
                blogText: attributes.blogText,
                blogUrl: attributes.blogUrl,
                address1: attributes.address1,
                address2: attributes.address2,
                address3: attributes.address3,
              };
            } else {
              this.formData = {};
              console.error(response.data?.error);
            }

            this.$store.commit("SET_LOADING", false);
          },

          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
    },
    // .......end------------
  },
};
</script>
