// Data sevice
import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class ProductService {
    getAll() {
        return axios.get(VUE_APP_BASE_URL + "menu-item", { headers: authHeader() });
    }
    store(resource) {
        return axios.post(VUE_APP_BASE_URL + "product", resource, {
            headers: authHeader(),
        });
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + "product/" + resource.id, resource, {
            headers: authHeader(),
        });
    }
    delete(resource) {
        return axios.delete(VUE_APP_BASE_URL + "product/" + resource.id, {
            headers: authHeader(),
        });
    }
    getMenuCategories() {
        return axios.get(VUE_APP_BASE_URL + "menu-category", {
            headers: authHeader(),
        });
    }
    getMenuGroupsByOrder(formData) {
        return axios.post(VUE_APP_BASE_URL + "menu-group-by-order", formData, {
            headers: authHeader(),
        });
    }
    getMenuCategoryByOrder(formData) {
        return axios.post(VUE_APP_BASE_URL + "menu-category-by-order", formData, {
            headers: authHeader(),
        });
    }
    getMenuGroupsByCategory(formData) {
        return axios.post(VUE_APP_BASE_URL + "menu-group-list", formData, {
            headers: authHeader(),
        });
    }
    getMenuItemsByGroup(formData) {
        return axios.post(VUE_APP_BASE_URL + "menu-item-list", formData, {
            headers: authHeader(),
        });
    }
}

export default new ProductService();
