<template>
  <div>
    <div class="card py-0" id="ordersTable">
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-list"></i>
              Post Services
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage all available eCommerce Services
            </p>
          </div>

          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div class="d-flex">
              <button
                class="btn btn-sm btn-falcon-primary ms-2"
                type="button"
                @click="goBack"
              >
                <!-- {{ this.formData }} -->
                <span class="fas fa-arrow-left" data-fa-transform=""></span> Go
                Back
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <!-- Start new table  -->
        <div class="px-4 py-2">
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <label class="col-form-label" for="message-text">Category</label>
              <select
                class="form-select"
                placeholder="Select"
                v-model="formData.category_id"
                :class="
                  v$.formData.category_id.$dirty &&
                  v$.formData.category_id.$invalid
                    ? `is-invalid`
                    : v$.formData.category_id.$dirty
                    ? `is-valid`
                    : ``
                "
              >
                <option value="">Select</option>
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.attributes.title }}
                </option>
              </select>
              <div
                v-for="error of v$.formData.category_id.$errors"
                :key="error.$uid"
                class="invalid-feedback"
                style="color: red"
              >
                This field is invalid
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <label class="col-form-label" for="category-title">Title</label>
              <input
                class="form-control"
                id="category-title"
                type="text"
                placeholder="e.g Pilau"
                v-model="formData.title"
                :class="
                  v$.formData.title.$dirty && v$.formData.title.$invalid
                    ? `is-invalid`
                    : v$.formData.title.$dirty
                    ? `is-valid`
                    : ``
                "
              />
              <div
                v-for="error of v$.formData.title.$errors"
                :key="error.$uid"
                class="invalid-feedback"
                style="color: red"
              >
                This field is invalid
              </div>
            </div>

            <!-- <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <label class="col-form-label" for="message-text"
                    >Description</label
                  >
                  <p class="text-word-break fs--1">
                    <textarea
                      v-model="formData.description"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </p>
                </div> -->

            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label class="col-form-label" for="message-text"
                >Description</label
              >
              <p class="text-word-break fs--1">
                <Document
                  :dataProp="this.formData.description"
                  @description="handleContent"
                />
              </p>
            </div>
          </div>
        </div>

        <!-- <div id="tableExample3" class="px-4 py-2"> -->

        <!-- <el-form-item
            label="Content"
            prop="content"
            :rules="[
              {
                required: true,
                message: 'Content is required',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <div style="height: 10px"></div>
            <div class="cke" style="width: 100%">
              <Document 
                    :dataProp="this.formData.description"
                    @content="handleContent"
                  />
            </div>
          </el-form-item> -->
        <!-- </div> -->
        <!-- end add table  -->

        <!-- <template #footer> -->
        <span
          class="dialog-footer px-4 py-2"
          style="float: right; padding: 10px"
        >
          <button
            class="btn btn-sm btn-falcon-primary ms-2"
            type="button"
            @click="handleClose"
          >
            Cancel
          </button>
          <button
            class="btn btn-sm btn-falcon-warning ms-2"
            type="button"
            @click="saveResource()"
          >
            Save Draft
          </button>
          <button
            class="btn btn-sm btn-falcon-success ms-2"
            type="button"
            @click="saveResource(1)"
            v-if="formData.publish == 0 || formData.publish == null"
          >
            Publish
          </button>
          <button
            class="btn btn-sm btn-falcon-danager ms-2"
            type="button"
            @click="saveResource(0)"
            v-if="formData.publish == 1"
          >
            Set Private
          </button>
        </span>
      </div>
    </div>

    <!-- start add modal  -->
    <!-- <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add Category</h4>
            </div>
























            <div class="px-4 py-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <label class="col-form-label" for="message-text"
                    >Category</label
                  >
                  <select
                    class="form-select"
                    placeholder="Select"
                    v-model="formData.category_id"
                    :class="
                      v$.formData.category_id.$dirty &&
                      v$.formData.category_id.$invalid
                        ? `is-invalid`
                        : v$.formData.category_id.$dirty
                        ? `is-valid`
                        : ``
                    "
                  >
                    <option value="">Select</option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.attributes.title }}
                    </option>
                  </select>
                  <div
                    v-for="error of v$.formData.category_id.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <label class="col-form-label" for="category-title"
                    >Title</label
                  >
                  <input
                    class="form-control"
                    id="category-title"
                    type="text"
                    placeholder="e.g Pilau"
                    v-model="formData.title"
                    :class="
                      v$.formData.title.$dirty && v$.formData.title.$invalid
                        ? `is-invalid`
                        : v$.formData.title.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.title.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <label class="col-form-label" for="message-text"
                    >Description</label
                  >
                  <p class="text-word-break fs--1">
                    <textarea
                      v-model="formData.description"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary"
              role="button"
              @click="saveResource"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <!-- End add modal  -->
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-body-row-height: 60px;
  --easy-table-body-row-font-size: 12px;
}

.page-link {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
</style>

<script>
import $ from "jquery";
import moment from "moment";
import mixins from "../mixins/index";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import AppBar from "@/components/AppBar.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import VueHtml2pdf from "vue3-html2pdf";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import OrderService from "../services/orders.service";
import OrdersService from "../services/orders.service";
import CustomerService from "../services/customer.service";
import TicketService from "../services/ticket.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import Pagination from "v-pagination-3";
import CountUp from "vue-countup-v3";
import ECommerceService from "../services/e-commerce.service";
import Document from "../components/Document.vue";

const now = new Date();

export default {
  mixins: [mixins],
  name: "E-CommerceCategoryView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        title: { required },
        category_id: { required },
        description: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    Pagination,
    VueHtml2pdf,
    LoaderOverlay,
    EasyDataTable,
    test: {
      value: 50,
    },
    analysis: {
      products: 6,
    },
    CountUp,
    Document,
    post: {},
  },

  data() {
    return {
      type: "voided",
      showing: [25, 100, 200, 500, "All"],
      user: {},
      per_page: 15,
      current_page: 0,
      records: 0,
      total: 0,
      paid: 0,
      unpaid: 0,
      daily_analysis: [{ id: 1, products: 20 }],
      selected: [],
      selected_items: "",
      sort_order: 0,
      orders_table_key: 0,
      enable_download: false,
      preview_modal: false,
      printarea_key: 1,
      resources: [],
      customers: [],
      config: {
        dateFormat: "M j, Y",
      },
      search: "",
      formData: {},
    };
  },
  created() {
    let data = { select: true };
    let params = JSON.parse(localStorage.getItem("params"));
    if (params != null) {
      this.formData = params;
      console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX", this.formData)
    }
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getEcoCategorySelect();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    // formData() {
    //   let params = JSON.parse(localStorage.getItem("params"));
    //   if (params != null) {
    //     return params;
    //   }
    // },
    loading() {
      return this.$store.getters.getLoading;
    },
    categories() {
      if (this.$store.state.e_commerce.e_category_select != undefined) {
        return this.$store.state.e_commerce.e_category_select.data;
      } else {
        return null;
      }
    },
    resources() {
      if (this.$store.state.e_commerce.e_services != undefined) {
        this.total = this.$store.state.e_commerce.e_services.total;
        this.records = this.$store.state.e_commerce.e_services.metadata.total;
        this.current_page =
          this.$store.state.e_commerce.e_services.metadata.current_page;
        this.next_page_url =
          this.$store.state.e_commerce.e_services.metadata.next_page_url;
        this.prev_page_url =
          this.$store.state.e_commerce.e_services.metadata.prev_page_url;
        this.first_page_url =
          this.$store.state.e_commerce.e_services.metadata.first_page_url;
        this.last_page_url =
          this.$store.state.e_commerce.e_services.metadata.last_page_url;
        return this.$store.state.e_commerce.e_services.data;
      } else {
        return null;
      }
    },
  },

  methods: {
    handleContent(description) {
      this.formData.description = description;
    },
    // handleFileUpload(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.selectedImage = file;
    //   }
    // },
    // saveResourceXXX(validate, p) {
    //   this.$refs[validate].validate((valid) => {
    //     if (valid) {
    //       let data = this.post;
    //       data.author_id = this.user;
    //       data.published_by = this.user;
    //       if (p) {
    //         data.publish = p;
    //       }

    //       const formData = new FormData();
    //       formData.append("feature_image", this.selectedImage);

    //       for (const key in data) {
    //         if (data.hasOwnProperty(key)) {
    //           formData.append(key, data[key]);
    //         }
    //       }

    //       this.loading = true;
    //       this.$apiEndpoints.addBlog(formData).then(
    //         (res) => {
    //           this.loading = false;
    //           this.$notify({
    //             title: "Success",
    //             message: res.data.message,
    //             type: "success",
    //           });
    //           this.post = {};
    //           this.$router.push("/blog/manage/blog/");
    //         },
    //         (error) => {
    //           this.loading = false;
    //           this.message =
    //             (error.response &&
    //               error.response.data &&
    //               error.response.data.message) ||
    //             error.message ||
    //             error.toString();
    //           this.$notify({
    //             title: "Error",
    //             message: this.message,
    //             type: "error",
    //           });
    //         }
    //       );
    //     } else {
    //       return false;
    //     }
    //   });
    // },

    getEcoCategorySelect() {
      this.$store.commit("SET_LOADING", true);
      this.$store.dispatch("e_commerce/getEcoCategorySelect", {}).then(
        (response) => {
          if (response.status === 200 && response?.data) {
            //
          } else {
            this.formData = {};
            console.error(response.data?.error);
          }
          this.$store.commit("SET_LOADING", false);
        },

        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    goBack() {
      this.handleClose();
      this.$router.push("/eshop-service");
    },
    saveResource(p) {
      this.v$.formData.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      if (p) {
        this.formData.publish = p;
      }
      this.formData.company_id = this.user.company_id;
      this.formData.author_id = this.user;
      this.formData.published_by = this.user;

      this.$store.commit("SET_LOADING", true);
      ECommerceService.createEcoServices(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success(response.data.message);
            this.formData = {};
          } else {
            console.log(response.data.error);
            toast.error(response.data.message);
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    async publish() {
      this.saveResource(1);
    },
    async setPrivate() {
      this.saveResource(0);
    },
    handleClose() {
      this.formData = {};
      localStorage.removeItem("params");
    },
  },
};
</script>
