<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-shopping-cart text-warning"></i>
              Inventory Warehouse
            </h5>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <button
                class="btn btn-falcon-warning btn-sm ms-2 rounded-pill my-auto"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
              >
                <span class="fas fa-plus-circle"></span
                ><span class="d-none d-sm-inline-block ms-1">New</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable
          id="tour-step-1"
          table-class-name="customize-table"
          alternating
          buttons-pagination
          :headers="headers"
          :items="resources"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
        >
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-name="item">
            <div class="my-2">
              <strong>{{ item.name.toUpperCase() }}</strong>
            </div>
          </template>
          <template #item-visible="item">
            <div class="my-2">
              <strong>{{ item.visible == 1 ? "Yes" : "No" }}</strong>
            </div>
          </template>
          <template #item-created_at="item">
            <div class="my-2">
              {{ formatDateHuman(item.created_at) }}
            </div>
          </template>
          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button
                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="order-dropdown-0"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div class="py-2">
                  <a
                    class="dropdown-item"
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-modal"
                    @click="editItem(item)"
                    >Edit</a
                  >
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    role="button"
                    @click="deleteRecource(item)"
                    >Delete</a
                  >
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>
    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="edit-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="edit-modalLabel'"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit Warehouse</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-12">
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="col-form-label" for="recipient-name">Name</label>
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g UGALI BEEF"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.visible ? `bg-info text-white` : ``"
                        ><span
                          class="me-2"
                          :class="
                            formData.visible ? `fa fa-check-circle` : `fa fa-times-circle`
                          "
                        ></span
                        >Active?
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="updateResource()"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- End edit modal  -->

    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal- mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Create Warehouse</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-12">
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="col-form-label" for="recipient-name">Name</label>
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g UGALI BEEF"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.visible ? `bg-info text-white` : ``"
                        ><span
                          class="me-2"
                          :class="
                            formData.visible ? `fa fa-check-circle` : `fa fa-times-circle`
                          "
                        ></span
                        >Active?
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import InventoryWarehouseService from "../services/inventory_warehouse.service";
import WarehouseService from "../services/warehouse.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      menu_item_sizes: [],
      warehouses: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        name: "",
        visible: 1,
        warehouse_id: "",
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["name", "mobile_no", "email"],
      headers: [
        { text: "#", value: "id", sortable: true, widh: 10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Visible", value: "visible", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    this.getWarehouse();
    this.getData();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  methods: {
    editItem(location) {
      this.formData = location;
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return InventoryWarehouseService.index().then(
        (response) => {
          console.group("resp");
          console.log(response.data);
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWarehouse() {
      return WarehouseService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.warehouses = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    getGroups() {
      return InventoryWarehouseService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.visible = !this.formData.visible;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      InventoryWarehouseService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.$store.commit("SET_LOADING", true);
      InventoryWarehouseService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm("Are you sure you want to delete? you cannot undo this action.");
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        InventoryWarehouseService.delete(resource).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
            } else {
              console.log(response);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
  },
};
</script>
