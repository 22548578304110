// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class UserService {

    index() {
        return axios.get(VUE_APP_BASE_URL + 'users', { headers: authHeader() })
    }
    validateEmail(email) {
        return axios.get(VUE_APP_BASE_URL + 'validate-email/'+email, { headers: authHeader() })
    }
    store(formData) {
        return axios.post(VUE_APP_BASE_URL + 'users', formData,  { headers: authHeader() })
    }
    subscribe(formData) {
        return axios.post(VUE_APP_BASE_URL + 'subscribe', formData,  { headers: authHeader() })
    }
    update(user) {
        return axios.put(VUE_APP_BASE_URL + 'users/' + user.id, user, { headers: authHeader() })
    }
    delete(user) {
        return axios.delete(VUE_APP_BASE_URL + 'users/' + user.auto_id, { headers: authHeader() })
    }
    getLoginUsers() {
        return axios.get(VUE_APP_BASE_URL + 'users-login', { headers: authHeader() })
    }
    getAllUsers() {
        return axios.get(VUE_APP_BASE_URL + 'users', { headers: authHeader() })
    }
    getAllCompanyUsers() {
        let user = JSON.parse(localStorage.getItem("user"));
        console.log(VUE_APP_BASE_URL + 'users/company/');
        return axios.get(VUE_APP_BASE_URL + 'users/company/' + user.company_id, { headers: authHeader() })
    }
    storeUser(user) {
        return axios.post(VUE_APP_BASE_URL + 'users', user, { headers: authHeader() })
    }
    updateUser(user) {
        return axios.put(VUE_APP_BASE_URL + 'users/' + user.auto_id, user, { headers: authHeader() })
    }
    deleteUser(user) {
        return axios.delete(VUE_APP_BASE_URL + 'users/' + user.auto_id, { headers: authHeader() })
    }
    reset(user) {
        return axios.post(VUE_APP_BASE_URL + 'auth/reset-pasword', user, { headers: authHeader() })
    }
    setPassword(user) {
        return axios.post(VUE_APP_BASE_URL + 'auth/set-pasword', user, { headers: authHeader() })
    }
}

export default new UserService()