// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class ExpenseService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'expense', { headers: authHeader() })
    }
    store(resource) {
        return axios.post(VUE_APP_BASE_URL + 'expense', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + 'expense/' + resource.id, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(VUE_APP_BASE_URL + 'expense/' + resource.id, { headers: authHeader() })
    }
    storeSingle(resource) {
        return axios.post(VUE_APP_BASE_URL + 'expense', resource, { headers: authHeader() })
    }
}

export default new ExpenseService()