// Authentication service

import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class AuthService {
  // login function
  login(user) {
    return axios
      .post(VUE_APP_BASE_URL + "auth/login", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        return response;
      });
  }
  // logout function
  logout() {
    localStorage.removeItem("user");
  }
  checkAuth() {
    return axios
      .get(VUE_APP_BASE_URL + "restaurant", { headers: authHeader() })
      .then(
        (response) => {
            console.log("ER:::"+ response.data.data.status)
          if (response.data.data.status == 2) {
            return 423;
          } else {
            return response.status;
          }
        },
        (error) => {
          return error.response.status;
        }
      )
  }
  // register function
  register(user) {
    return axios.post(VUE_APP_BASE_URL + "auth/register", {
      name: user.name,
      email: user.email,
      password: user.password,
      company_name: user.company_name,
      password_confirmation: user.password,
    });
  }
}

export default new AuthService();
