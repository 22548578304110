import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class ReportService {
  logs(formData) {
    return axios.post(VUE_APP_BASE_URL + "logs", formData, {
      headers: authHeader(),
    });
  }
  sales(formData) {
    return axios.post(VUE_APP_BASE_URL + "sales-report", formData, {
      headers: authHeader(),
    });
  }
  openOrdersReport(formData) {
    return axios.post(VUE_APP_BASE_URL + "open-orders-report", formData, {
      headers: authHeader(),
    });
  }
  salesAnalysis(formData) {
    return axios.post(VUE_APP_BASE_URL + "sales-analysis", formData, {
      headers: authHeader(),
    });
  }
  profitAnalysis(formData) {
    return axios.post(VUE_APP_BASE_URL + "profit-analysis", formData, {
      headers: authHeader(),
    });
  }
  salesAnalysisByCategory(formData) {
    return axios.post(
      VUE_APP_BASE_URL + "sales-analysis-bycategory",
      formData,
      {
        headers: authHeader(),
      }
    );
  }
  salesAnalysisDaily(formData) {
    return axios.post(VUE_APP_BASE_URL + "sales-analysis-daily", formData, {
      headers: authHeader(),
    });
  }
  dailyTransfers(formData) {
    return axios.post(VUE_APP_BASE_URL + "stock-transfer-report", formData, {
      headers: authHeader(),
    });
  }
  dailyWaste(formData) {
    return axios.post(VUE_APP_BASE_URL + "stock-waste-report", formData, {
      headers: authHeader(),
    });
  }
  dailyAdjustments(formData) {
    return axios.post(VUE_APP_BASE_URL + "stock-adjustment-report", formData, {
      headers: authHeader(),
    });
  }
  dailyRestocks(formData) {
    return axios.post(VUE_APP_BASE_URL + "stock-restock-report", formData, {
      headers: authHeader(),
    });
  }
  lowStock(formData) {
    return axios.post(VUE_APP_BASE_URL + "low-stock-analysis", formData, {
      headers: authHeader(),
    });
  }
  salesReportOrderwise(formData) {
    return axios.post(VUE_APP_BASE_URL + "sales-report-orderwise", formData, {
      headers: authHeader(),
    });
  }

  // start: analysis
  getDailyAnalysis(data) {
    return axios
      .post(VUE_APP_BASE_URL + "daily-analysis", data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  getBestSeller(data) {
    return axios
      .post(VUE_APP_BASE_URL + "best-seller-report", data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  getStockAnalysis(data) {
    return axios
      .post(VUE_APP_BASE_URL + "stock-analysis", data, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
  getGeneralAnalysis(data) {
    return axios
      .post(VUE_APP_BASE_URL + "general-analysis", data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  // end: analysis

  weeklyAnalysis() {
    return axios.get(VUE_APP_BASE_URL + "weekly-analysis", {
      headers: authHeader(),
    });
  }

  paymentAnalysis(formData) {
    return axios.post(VUE_APP_BASE_URL + "payment-analysis", formData, {
      headers: authHeader(),
    });
  }
  bestSeller() {
    return axios.get(VUE_APP_BASE_URL + "best-seller", {
      headers: authHeader(),
    });
  }
  bestSellerReport() {
    return axios.get(VUE_APP_BASE_URL + "best-seller", {
      headers: authHeader(),
    });
  }
}

export default new ReportService();
