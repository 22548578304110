import ECommerceService from "../services/e-commerce.service";

export const e_commerce = {
  namespaced: true,

  state: {},

  actions: {
    getWebisiteCofig({ commit }, data) {
      return ECommerceService.getWebisiteCofig(data).then((data) => {
        commit("e_website_configs", data);
        return Promise.resolve(data);
      });
    },
    getEcoCategory({ commit }, data) {
      return ECommerceService.getEcoCategory(data).then((data) => {
        commit("e_category", data);
        return Promise.resolve(data);
      });
    },
    getEcoCategorySelect({ commit }, data) {
      return ECommerceService.getEcoCategorySelect(data).then((data) => {
        commit("e_category_select", data);
        return Promise.resolve(data);
      });
    },
    getEcoServices({ commit }, data) {
      return ECommerceService.getEcoServices(data).then((data) => {
        commit("e_services", data);
        return Promise.resolve(data);
      });
    },
    getEcoSolutions({ commit }, data) {
      return ECommerceService.getEcoSolutions(data).then((data) => {
        commit("e_solution", data);
        return Promise.resolve(data);
      });
    },
  },

  mutations: {
    e_website_configs(state, data) {
      state.e_website_configs = data;
    },
    e_category(state, data) {
      state.e_category = data;
    },
    e_category_select(state, data) {
      state.e_category_select = data;
    },
    e_services(state, data) {
      state.e_services = data;
    },
    e_solution(state, data) {
      state.e_solution = data;
    },
  }
}
