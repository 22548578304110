<template>
  <div>
    <div class="mb-2">
      <div class="card theme-wizard mb-2">
        <div class="border-bottom pt-2">
          <ul class="nav justify-content-between nav-wizard">
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">1</span></span
                ><span class="d-none d-md-block mt-1 fs--1"
                  >Create Locations</span
                ></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">2</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Categories</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">3</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Groups</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">3</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Menu Items</span></a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div
              class="row g-3 d-flex tab-pane active"
              role="tabpanel"
              aria-labelledby="bootstrap-wizard-tab1"
              id="bootstrap-wizard-tab1"
            >
              <div class="col-3 text-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_flagged_2uty.svg"
                  alt="Product Image"
                  width="100"
                />
              </div>
              <div class="col-3 d-flex justify-content-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_confirmation_re_b6q5.svg"
                  alt="Product Image"
                  width="100"
                />
              </div>
              <div class="col-3 d-flex justify-content-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_shopping_app_flsj.svg"
                  alt="Product Image"
                  width="100"
                />
              </div>
              <div class="col-3 d-flex justify-content-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_product_iteration_kjok.svg"
                  alt="Product Image"
                  width="100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-sm-4 col-xs-4 align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-shopping-cart text-warning"></i>
              Menu Items
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Generate menu items
            </p>
          </div>
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <button
                class="btn btn-sm btn-falcon-warning ms-2 ms-auto"
                type="file"
                data-bs-toggle="modal"
                data-bs-target="#import-modal"
                data-toggle="tooltip"
                data-placement="top"
                title="Import product"
              >
                <i class="fas fa-file-excel"></i> Import
              </button>

              <button
                class="btn btn-sm btn-falcon-warning ms-2"
                type="file"
                data-toggle="tooltip"
                data-placement="top"
                title="Export product"
                @click="exportData"
              >
                <i class="fas fa-file-excel"></i> Export
              </button>
              <button
                class="btn btn-sm btn-rounded btn-falcon-warning ms-2"
                type="button"
                @click="addNew"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                data-toggle="tooltip"
                data-placement="top"
                title="Add new product"
              >
                <i class="fas fa-plus"></i>
                New
              </button>
              <button
                @click="forceReload"
                class="btn btn-sm btn-falcon-warning ms-2"
                data-toggle="tooltip"
                data-placement="top"
                title="Refresh page"
              >
                <i class="fa-solid fa-arrows-rotate"></i> Refresh
              </button>
              <button
                @click="goToBarcode"
                class="btn btn-sm btn-falcon-warning ms-2"
                data-toggle="tooltip"
                data-placement="top"
                title="Open barcode page"
              >
                <i class="fa-solid fa-barcode"></i> Barcodes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <div
          id="tableExample3"
          data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
        >
          <div class="row justify-content-start g-0">
            <div class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3">
              <select
                class="form-select"
                id="recipient-name"
                type="text"
                @change="selectOrderType"
                style="width: auto"
              >
                <option value="all">All Items</option>
                <option
                  v-for="(item, i) in order_types"
                  :key="i"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div
              class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3 d-flex ms-auto"
            >
              <div class="input-group ms-auto me-2">
                <input
                  class="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search..."
                  aria-label="search"
                  v-model="search_term"
                  v-on:keyup="searchItem"
                />
                <div class="input-group-text bg-transparent">
                  <span class="fa fa-search fs--1 text-600"></span>
                </div>
              </div>
              <button class="btn btn-falcon-warning mx-1" @click="goToPOS">
                POS
              </button>
            </div>
          </div>
          <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-0">
              <thead class="bg-200 text-900">
                <tr>
                  <th class="sort" data-sort="name">Item #</th>
                  <th class="sort" data-sort="email">Photo</th>
                  <th class="sort" data-sort="age">Name</th>
                  <!-- <th class="sort" data-sort="age">Group</th> -->
                  <th class="sort" data-sort="age">B.Price</th>
                  <th class="sort" data-sort="age">S.Price</th>
                  <th class="sort" data-sort="age">WS.Price</th>
                  <!-- <th class="sort" data-sort="age">Offer Price</th> -->
                  <th class="sort" data-sort="age">Details</th>
                  <th class="sort" data-sort="age">Stock Amount</th>
                  <th class="sort" data-sort="age">Units</th>
                  <th class="sort" data-sort="age">Action</th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="(item, i) in resources" :key="i">
                  <td>{{ item.id }}</td>
                  <td>
                    <div class="avatar avatar-xl">
                      <img
                        v-if="item.image"
                        class="rounded-circle"
                        :src="item.image"
                        alt="Product Image"
                        height="32"
                      />
                      <img
                        v-else
                        class="rounded-circle"
                        src="assets/img/no-image.jpg"
                        alt="Product Image"
                        height="32"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="my-2">
                      <strong>{{ item.name.toUpperCase() }}</strong>
                      <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
                    </div>
                  </td>
                  <!-- <td>{{ item.group ? item.group.name : "" }}</td> -->
                  <td>
                    <input
                      :value="item.buy_price"
                      @change="updateBP($event, item)"
                    />
                  </td>
                  <td>
                    <input
                      :value="item.price"
                      @change="updateSP($event, item)"
                    />
                  </td>
                  <td>
                    <input
                      :value="item.ws_price"
                      @change="updateWP($event, item)"
                    />
                  </td>
                  <!-- <td>{{ item.offer_price }}</td> -->
                  <td>{{ item.description }}</td>
                  <td>{{ item.stock_amount }}</td>
                  <td>{{ item.unit_name }}</td>
                  <td>
                    <div class="dropdown font-sans-serif position-static">
                      <button
                        class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                        type="button"
                        id="order-dropdown-0"
                        data-bs-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="fas fa-ellipsis-h fs--1"></span>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-end border py-0"
                        aria-labelledby="order-dropdown-0"
                      >
                        <div class="py-2">
                          <a
                            class="dropdown-item"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#editModal"
                            @click="editItem(item)"
                          >
                            Edit
                          </a>
                          <div class="dropdown-divider"></div>

                          <div v-if="etims_integrated">
                            <a
                              class="dropdown-item"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#etimsModal"
                              @click="editItem(item)"
                            >
                              Etims composition
                            </a>
                            <div class="dropdown-divider"></div>
                          </div>

                          <a
                            v-if="user.permissions.includes('void-ticket')"
                            class="dropdown-item text-danger"
                            role="button"
                            @click="deleteResource(item)"
                            >Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <pagination
              v-model="current_page"
              :records="records"
              :per-page="per_page"
              @paginate="myCallback"
            />
          </div>
        </div>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="editModal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="editModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              id="edit-modal-close"
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">
                {{ formData.id ? `Edit` : `New` }} Products
              </h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <div
                  v-if="show_location_error"
                  class="alert alert-danger"
                  role="alert"
                >
                  You must select at least one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Select Group</label
                        >
                        <select
                          class="form-select"
                          id="recipient-name"
                          type="text"
                          v-model="formData.group_id"
                          @change="groupSelected"
                          :class="
                            v$.formData.group_id.$dirty &&
                            v$.formData.group_id.$invalid
                              ? `is-invalid`
                              : v$.formData.group_id.$dirty
                              ? `is-valid`
                              : ``
                          "
                        >
                          <option
                            v-for="(item, i) in groups"
                            :key="i"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <div
                          v-for="error of v$.formData.group_id.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Buying Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.buy_price"
                          :class="
                            v$.formData.buy_price.$dirty &&
                            v$.formData.buy_price.$invalid
                              ? `is-invalid`
                              : v$.formData.buy_price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.buy_price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Selling Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.price"
                          :class="
                            v$.formData.price.$dirty &&
                            v$.formData.price.$invalid
                              ? `is-invalid`
                              : v$.formData.price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >WS Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.ws_price"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Offer Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.offer_price"
                          :class="
                            v$.formData.offer_price.$dirty &&
                            v$.formData.offer_price.$invalid
                              ? `is-invalid`
                              : v$.formData.offer_price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Stock Quantity</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          disabled
                          placeholder="e.ge 10"
                          v-model="formData.stock_amount"
                        />
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <!-- ORDER TYPES QUANTITIES  -->
                    <div
                      v-for="(item, i) in formData.selected_order_types"
                      :key="i"
                      class="row"
                    >
                      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >{{ item.name.toUpperCase() }} Quantity</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          :value="
                            location_quantities.find(
                              (el) => el.location_id == item.id
                            )
                              ? location_quantities.find(
                                  (el) => el.location_id == item.id
                                ).quantity
                              : 0
                          "
                          @change="addLocationQty($event, item)"
                        />
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Re-order level</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          :value="
                            location_quantities.find(
                              (el) => el.location_id == item.id
                            )
                              ? location_quantities.find(
                                  (el) => el.location_id == item.id
                                ).reorder
                              : 0
                          "
                          @change="addLocationReorderQty($event, item)"
                        />
                      </div>
                      <hr class="mt-2" />
                    </div>
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Barcode</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.barcode"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Photo</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                          @change="onFileChange"
                        />
                      </div>
                      <label class="col-form-label" for="message-text"
                        >Description</label
                      >
                      <p class="text-word-break fs--1">
                        <textarea
                          v-model="formData.description"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </p>

                      <!-- <p>Etims Intergrated {{ etims_integrated }}</p> -->
                      <div v-if="etims_integrated"> 
                        <hr/>
                        <h6 class="fw-semi-bold ls mb-3 text-uppercase">eTims Details</h6>
                        <p> Make sure all etims information is filled </p>
                        <hr />
                        <div class="d-flex">
                          <div class="flex-1">
                            <div class="row mt-2">
                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-kra-pin"
                                  >Kra Pin</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-kra-pin"
                                  type="text"
                                  v-model="this.etims.tin"
                                  disabled
                                />
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-bhfId"
                                  >Branch ID</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-bhfId"
                                  type="text"
                                  v-model="this.etims.bhfId"
                                  disabled
                                />
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-itemClsCd"
                                  >Item Code</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-itemClsCd"
                                  type="text"
                                  v-model="formData.itemClsCd"
                                  :class="
                                    v$.formData.itemClsCd.$dirty &&
                                    v$.formData.itemClsCd.$invalid
                                      ? `is-invalid`
                                      : v$.formData.itemClsCd.$dirty
                                      ? `is-valid`
                                      : ``
                                  "
                                />
                                <div
                                  v-for="error of v$.formData.itemClsCd.$errors"
                                  :key="error.$uid"
                                  class="invalid-feedback"
                                  style="color: red"
                                >
                                  This field is invalid
                                </div>
                              </div>

                              <!-- <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-itemCd"
                                  >Item Classification Code</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-itemCd"
                                  type="text"
                                  v-model="formData.itemCd"
                                />
                              </div> -->

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-itemTyCd"
                                  >item Type Code</label
                                >
                                <select
                                  class="form-select nav-link nav-link-card-details"
                                  id="etims-itemTyCd"
                                  type="text"
                                  v-model="formData.itemTyCd"
                                >
                                  <option value="">Codes...</option>
                                  <option
                                    v-for="(item, i) in etims_item_type_codes"
                                    :key="i"
                                    :value="item.code"
                                  >
                                    {{ item.description }}
                                  </option>
                                </select>
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-name"
                                  >item Name</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-name"
                                  type="text"
                                  v-model="formData.itemNm"
                                  :class="
                                      v$.formData.itemNm.$dirty &&
                                      v$.formData.itemNm.$invalid
                                        ? `is-invalid`
                                        : v$.formData.itemNm.$dirty
                                        ? `is-valid`
                                        : ``
                                    "
                                  />
                                  <div
                                    v-for="error of v$.formData.itemNm.$errors"
                                    :key="error.$uid"
                                    class="invalid-feedback"
                                    style="color: red"
                                  >
                                    This field is invalid
                                  </div>
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-orgnNatCd"
                                  >Origin Place Code(Nation)</label
                                >
                                <select
                                  class="form-select nav-link nav-link-card-details"
                                  id="etims-orgnNatCd"
                                  type="text"
                                  v-model="formData.orgnNatCd"
                                >
                                  <option value="">Codes...</option>
                                  <option
                                    v-for="(item, i) in etims_country_codes"
                                    :key="i"
                                    :value="item.code"
                                  >
                                    {{ item.description }}
                                  </option>
                                </select>
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-pkgUnitCd"
                                  >Packaging Unit Code</label
                                >
                                <select
                                  class="form-select nav-link nav-link-card-details"
                                  id="etims-pkgUnitCd"
                                  type="text"
                                  v-model="formData.pkgUnitCd"
                                >
                                  <option value="">Codes...</option>
                                  <option
                                    v-for="(item, i) in etims_packaging_unit_codes"
                                    :key="i"
                                    :value="item.code"
                                  >
                                    {{ item.description }}
                                  </option>
                                </select>
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-qtyUnitCd"
                                  >Quantity Unit Code</label
                                >
                                <select
                                  class="form-select nav-link nav-link-card-details"
                                  id="etims-qtyUnitCd"
                                  type="text"
                                  v-model="formData.qtyUnitCd"
                                >
                                  <option value="">Codes...</option>
                                  <option
                                    v-for="(item, i) in etims_quantity_unit_codes"
                                    :key="i"
                                    :value="item.code"
                                  >
                                    {{ item.description }}
                                  </option>
                                </select>
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-taxTyCd"
                                  >Taxation Type Code</label
                                >
                                <select
                                  class="form-select nav-link nav-link-card-details"
                                  id="etims-taxTyCd"
                                  type="text"
                                  v-model="formData.taxTyCd"
                                >
                                  <option value="">Codes...</option>
                                  <option
                                    v-for="(item, i) in etims_taxation_code_types"
                                    :key="i"
                                    :value="item.name"
                                  >
                                    {{ item.description }}
                                  </option>
                                </select>
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-dftPrc"
                                  >Default Unit Price</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-dftPrc"
                                  type="text"
                                  v-model="formData.dftPrc"
                                />
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-isrcAplcbYn"
                                  >Insurance Appicable(Y/N)</label
                                >
                                <select
                                  class="form-select nav-link nav-link-card-details"
                                  id="etims-isrcAplcbYn"
                                  type="text"
                                  v-model="formData.isrcAplcbYn"
                                >
                                  <option value="">Applicability...</option>
                                  <option
                                    v-for="(item, i) in etims_insuarance_applicable"
                                    :key="i"
                                    :value="item.name"
                                  >
                                    {{ item.description }}
                                  </option>
                                </select>
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-useYn"
                                  >Etims Used / UnUsed</label
                                >
                                <select
                                  class="form-select nav-link nav-link-card-details"
                                  id="etims-useYn"
                                  type="text"
                                  v-model="formData.useYn"
                                >
                                  <option value="">used...</option>
                                  <option
                                    v-for="(item, i) in etims_used"
                                    :key="i"
                                    :value="item.name"
                                  >
                                    {{ item.description }}
                                  </option>
                                </select>
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-regrNm"
                                  >Registrant Name</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-regrNm"
                                  type="text"
                                  v-model="formData.regrNm"
                                />
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-regrId"
                                  > Registrant ID</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-regrId"
                                  type="text"
                                  v-model="formData.regrId"
                                />
                              </div>


                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-modrNm"
                                  >Modifier Name</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-modrNm"
                                  type="text"
                                  v-model="formData.modrNm"
                                />
                              </div>

                              <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label class="col-form-label" for="etims-modrId"
                                  > Modifier ID</label
                                >
                                <input
                                  class="form-control"
                                  id="etims-modrId"
                                  type="text"
                                  v-model="formData.modrId"
                                />
                              </div>


                            </div>
                            <hr class="my-2" />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="formData.barcode && formData.barcode.length > 0"
                        class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      >
                        <div class="border border-success text-center">
                          <label
                            class="col-form-label mb-0 text-decoration-underline"
                            for="recipient-name"
                            >{{
                              formData.name ? formData.name.toUpperCase() : ""
                            }}</label
                          >
                          <br />
                          <p>{{ formData.description }}</p>
                          <div>
                            <vue-barcode
                              :value="formData.barcode"
                              :height="50"
                            />
                          </div>
                          <span>{{ formatToCurrency(formData.price) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="position-relative">
                  <hr />
                  <div class="divider-content-center">Other Actions</div>
                </div>
                <ul class="nav flex-lg-column fs--1">
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleActive"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.visible ? `bg-warning text-white` : ``"
                      ><span class="fa fa-question me-2"></span>Active?
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleSellable"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.sellable ? `bg-warning text-white` : ``"
                      ><span class="fa fa-question me-2"></span>Sellable?
                    </a>
                  </li>

                  <!-- This will show the value of etims_integrated for debugging -->
                  <!-- <p>Etims Integrated: {{ etims_integrated }}</p> -->

                  <!-- Conditional rendering based on etims_integrated -->
                  <div v-if="etims_integrated">
                    <label class="col-form-label" for="message-text">Codes</label>
                    <select
                      class="form-select nav-link nav-link-card-details"
                      id="recipient-name"
                      type="text"
                    >
                      <option value="">Etim Codes...</option>
                      <option
                        v-for="(item, i) in etims_code_list"
                        :key="i"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    <a
                      @click="toggleVAT"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.vat ? 'bg-warning text-white' : ''"
                    >
                      <span class="fa fa-question me-2"></span>VAT(16%)
                    </a>
                  </div>

                  <li class="nav-item me-2 me-lg-0 my-2">
                    <label class="col-form-label" for="message-text"
                      >Size</label
                    >
                    <select
                      class="form-select nav-link nav-link-card-details"
                      id="recipient-name"
                      type="text"
                      v-model="formData.unit_name"
                    >
                      <option value="">Unit...</option>
                      <option
                        v-for="(item, i) in menu_item_sizes"
                        :key="i"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </li>
                  <hr />

                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Select Locations</div>
                  </div>
                  <div
                    class="border"
                    :class="
                      show_location_error ? 'border-danger' : 'border-success'
                    "
                  >
                    <li
                      v-for="(item, i) in order_types"
                      :key="i"
                      class="nav-item me-2 me-lg-0 my-2"
                    >
                      <a
                        @click="addToLocations(item)"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="
                          formData.order_types &&
                          formData.order_types.includes(item.id)
                            ? `bg-info text-white`
                            : ``
                        "
                        ><span class="fa fa-check me-2"></span>
                        {{ item.name }}
                      </a>
                    </li>
                  </div>

                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Actions</div>
                  </div>
                  <button class="d-flex ms-uto btn btn-falcon-primary">
                    Print Barcode
                  </button>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="formData.id"
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="updateResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Update</span>
            </button>
            <button
              v-else
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->



    <!-- etims composition and save modal  -->
    <div
      class="modal fade"
      id="etimsModal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="etimsModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              id="etims-modal-close"
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">
                {{ formData.id ? `Edit` : `New` }} eTims Composition
              </h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div
                  v-if="show_location_error"
                  class="alert alert-danger"
                  role="alert"
                >
                  You must select at least one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >KRA PIN</label
                        >
                        <input
                          class="form-control"
                          id="recipient-tin"
                          type="text"
                          v-model="this.etims.tin"
                          disabled
                        />
                      </div>


                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-bhfId"
                          >Branch ID</label
                        >
                        <input
                          class="form-control"
                          id="recipient-bhfId"
                          type="text"
                          v-model="this.etims.bhfId"
                          disabled
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Item cpstQty</label
                        >
                        <input
                          class="form-control"
                          id="recipient-cpstQty"
                          type="number"
                          placeholder="e.g 10"
                          v-model="formData.cpstQty"
                        />
                      </div>


                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Registration name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-regrNm"
                          type="text"
                          v-model="formData.regrNm"
                        />
                      </div>


                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          > Registrant ID</label
                        >
                        <input
                          class="form-control"
                          id="recipient-regrId"
                          type="text"
                          v-model="formData.regrId"
                        />
                      </div>


                    </div>
                    <hr class="my-2" />
                  </div>
                </div>
                
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-if="formData.id"
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="updateResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Update</span>
            </button>
            <button
              v-else
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Endetims composition and save modal  -->

    <!-- Start import modal  -->
    <div
      class="modal fade"
      id="import-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="import-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
            id="import-modal-close"
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add Products</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div
                  v-if="show_location_error"
                  class="alert alert-danger"
                  role="alert"
                >
                  You must select atleast one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input
                          type="file"
                          id="import-excel"
                          @change="importExcel()"
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <a
                          href="https://docs.google.com/spreadsheets/d/1nVyV3pfREpDOhZgaQk9zhEt-G8GvX8QU/edit?usp=sharing&ouid=111166152502013029101&rtpof=true&sd=true"
                          target="_blank"
                          class="text-decoration-underline"
                          >Click here to download the excel template</a
                        >
                      </div>
                    </div>
                    <hr class="my-2" />
                    <div class="table-responsive">
                      <table class="table">
                        <caption>
                          List of items
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Barcode</th>
                            <th scope="col">Description</th>
                            <th scope="col">B.P</th>
                            <th scope="col">S.P</th>
                            <th scope="col">WS.P</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Units</th>
                            <th scope="col">Category</th>
                            <th scope="col">Taxable</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in import_resources" :key="i">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>{{ item[0] }}</td>
                            <td>{{ item[1] }}</td>
                            <td>{{ item[2] }}</td>
                            <td>{{ item[3] }}</td>
                            <td>{{ item[4] }}</td>
                            <td>{{ item[5] }}</td>
                            <td>{{ item[6] }}</td>
                            <td>{{ item[7] }}</td>
                            <td>{{ item[8] }}</td>
                            <td>{{ item[9] }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-warning ms-2 rounded-pill h1 d-block"
              type="button"
              @click="uploadData"
            >
              <span class="d-none d-sm-inline-block ms-1">
                <i class="fa fa-cloud"></i> Upload</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End import modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import Pagination from "v-pagination-3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import VueBarcode from "@chenfengyuan/vue-barcode";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import CartService from "../services/cart.service";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuItemsService from "../services/menu_item.service";
import MenuGroupService from "../services/menu_group.service";
import MenuItemSizeService from "../services/menu_item_size.service";
import OrderTypeService from "../services/order_types.service";
import EtimsService from "../services/etims.service";

import readXlsFile from "read-excel-file";
import exportXlsFile from "export-from-json";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  // validations() {
  //   return {
  //     formData: {
  //       name: { required },
  //       price: { required },
  //       group_id: { required },
  //       buy_price: { required },
  //       offer_price: {required},
  //     },
  //   };
  // },

  validations() {
    const baseValidations = {
      formData: {
        name: { required },
        price: { required },
        group_id: { required },
        buy_price: { required },
        offer_price: { required },
      },
    };

    if (this.etims.etims_integrated) {
      baseValidations.formData.itemClsCd = { required };
      // baseValidations.formData.itemCd = { required };
      baseValidations.formData.itemTyCd = { required };
      baseValidations.formData.itemNm = { required };
      baseValidations.formData.orgnNatCd = { required };
      baseValidations.formData.pkgUnitCd = { required };
      baseValidations.formData.qtyUnitCd = { required };
      baseValidations.formData.taxTyCd = { required };
      baseValidations.formData.dftPrc = { required };
      baseValidations.formData.isrcAplcbYn = { required };
      baseValidations.formData.useYn = { required };
      baseValidations.formData.regrNm = { required };
      baseValidations.formData.regrId = { required };
      baseValidations.formData.modrNm = { required };
      baseValidations.formData.modrId = { required };
    }

    return baseValidations;
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    Pagination,
    VueBarcode,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      per_page: 18,
      current_page: 0,
      records: 0,
      search_term: "",
      file: "",
      order_type: "all",
      order_types: [],
      filename: "",
      selected: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      selected_items: "",
      import_resources: [],
      menu_item_sizes: [],
      productCategories: [],
      selected_category: "",
      formData: {
        vat: 0,
        group_id: "",
        active: 0,
        visible: 1,
        unit_name: "",
        description: "",
        order_types: [],
        barcode: "",
        sellable: 0,
        stock_amount: 0,
        offer_price: 0,
        selected_order_types: [],
        // tin:"", // KRA PIN
        // bhfId:"", // Branch ID
        itemClsCd:"", // Item Code
        //itemCd:"",    // Item Classification Code
        itemTyCd:"",  // item Type Code
        itemNm:"",    // item Name
        orgnNatCd:"", // Origin Place Code (Nation)
        pkgUnitCd:"", // Packaging Unit Code
        qtyUnitCd:"", // Quantity Unit Code
        taxTyCd:"",   // Taxation Type Code
        dftPrc:"",    // Default Unit Price
        isrcAplcbYn:"N", //Insurance Appicable(Y/N)
        useYn:"N",   // Yes/No(Y/N)
        regrNm:"",    // Registrant Name
        regrId:"",    // Registrant ID
        modrNm:"",    // Modifier Name
        modrId:"",    // Modifier ID'
      },
      resources: [],
      location_quantities: [],
      orders_table_key: 0,
      show_location_error: false,
      searchField: ["id", "name", "price"],
      headers: [
        { text: "#", value: "id", sortable: true, width: 10 },
        { text: "Photo", value: "image", sortable: true, width: 10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Group", value: "group_id", sortable: true },
        { text: "B.Price", value: "buy_price", sortable: true },
        { text: "S.Price", value: "price", sortable: true },
        { text: "Details", value: "description", sortable: true },
        { text: "Barcode", value: "barcode", sortable: true },
        { text: "Stock Amount", value: "stock_amount", sortable: true },
        { text: "Units", value: "unit_name", sortable: true },
        { text: "Action", value: "action" },
      ],
      etims_integrated: 0,
      etims_code_list: [],
      etims_taxation_code_types: [
        { name: 'A', description: 'A-Exempt' },
        { name: 'B', description: 'B-16.00%' },
        { name: 'C', description: 'C-0%' },
        { name: 'D', description: 'D- Non-VAT' }, 
        { name: 'E', description: 'E-8%' } 
      ],
      etims_insuarance_applicable:[{ name: 'Y', description: 'YES' },{ name: 'N', description: 'NO' }],
      etims_used:[{ name: 'Y', description: 'YES' },{ name: 'N', description: 'NO' }],
      etims_packaging_unit_codes:[
        { code: 'AM', description: 'AM - Ampoule'},  
        { code: 'BA', description: 'BA - Barrel '},
        { code: 'BC', description: 'BC - Bottlecrate'}, 
        { code: 'BE', description: 'BE - Bundle'},
        { code: 'BF', description: 'BF - Balloon, non-protected Balloon'},
        { code: 'BG', description: 'BG - Bag'},
        { code: 'BJ', description: 'BJ - Bucket'},
        { code: 'BK', description: 'BK - Basket'},
        { code: 'BL', description: 'BL - Bale'},
        { code: 'BQ', description: 'BQ - Bottle, protected cylindrical Bottle'},
        { code: 'BR', description: 'BR - Bar'},
        { code: 'BV', description: 'BV - Bottle, bulbous'},
        { code: 'BZ', description: 'BZ - Bag'}, 
        { code: 'CA', description: 'CA - Can'},  
        { code: 'CH', description: 'CH - Chest'},
        { code: 'CJ', description: 'CJ - Coffin'}, 
        { code: 'CL', description: 'CL - Coil'},
        { code: 'CR', description: 'CR - Wooden Box, Wooden Case'},
        { code: 'CS', description: 'CS - Cassette'},
        { code: 'CT', description: 'CT - Carton'}, 
        { code: 'CTN', description: 'CTN - Container'}, 
        { code: 'CY', description: 'CY - Cylinder'}, 
        { code: 'DR', description: 'DR - Drum'},
        { code: 'GT', description: 'GT - Extra Countable Item'},
        { code: 'HH', description: 'HH - Hand Baggage'},
        { code: 'IZ', description: 'IZ - Ingots'}, 
        { code: 'JR', description: 'JR - Jar'}, 
        { code: 'JU', description: 'JU - Jug'},
        { code: 'JY', description: 'JY - Jerry CAN Cylindrical'},
        { code: 'KZ', description: 'KZ - Canester'}, 
        { code: 'LZ', description: 'LZ - Logs, in bundle/bunch/truss Logs'},
        { code: 'NT', description: 'NT - Net'},
        { code: 'OU', description: 'OU - Non-Exterior Packaging Unit'},
        { code: 'PD', description: 'PD - Poddon'},  
        { code: 'PG', description: 'PG - Plate'},  
        { code: 'PI', description: 'PI - Pipe'},  
        { code: 'PO', description: 'PO - Pilot'}, 
        { code: 'PU', description: 'PU - Traypack'}, 
        { code: 'RL', description: 'RL - Reel'}, 
        { code: 'RO', description: 'RO - Roll'}, 
        { code: 'RZ', description: 'RZ - Rods, in bundle/bunch/truss'},
        { code: 'SK', description: 'SK - Skeletoncase'},  
        { code: 'TY', description: 'TY - Tank, cylindrical'}, 
        { code: 'VG', description: 'VG - Bulk,gas(at 1031 mbar 15 oC)'}, 
        { code: 'VL', description: 'VL - Bulk,liquid(at normal temperature/pressure)'},
        { code: 'VO', description: 'VO - Bulk, solid, large particles(nodules)'},
        { code: 'VQ', description: 'VQ - Bulk, gas (liquefied at abnormal temperature/pressure)'},
        { code: 'VR', description: 'VR - Bulk, solid, granular particles(grains)'},
        { code: 'VT', description: 'VT - Extra Bulk Item'}, 
        { code: 'VY', description: 'VY - Bulk, fine particles(powder)'},
        { code: 'ML', description: 'ML - Mills cigarette Mills'}, 
        { code: 'TN', description: 'TN - TAN - 1TAN REFER TO 20BAGS'}
      ],
      etims_quantity_unit_codes:[
        { code: '4B', description: '4B - Pair'}, 
        { code: 'AV', description: 'AV - Cap'},
        { code: 'BA', description: 'BA - Barrel'},
        { code: 'BE', description: 'BE - bundle'},
        { code: 'BG', description: 'BG - bag'},
        { code: 'BL', description: 'BL - block'},
        { code: 'BLL' , description: 'BLL - BLL Barrel (petroleum) (158,987 dm3)'},
        { code: 'BX', description: 'BX - box'},  
        { code: 'CA', description: 'CA - Can'},
        { code: 'CEL', description: 'CEL - Cell'},
        { code: 'CMT', description: 'CMT - centimetre'}, 
        { code: 'CR', description: 'CR - CARAT'},
        { code: 'DR', description: 'DR - Drum'},
        { code: 'DZ', description: 'DZ - Dozen'}, 
        { code: 'GLL', description: 'GLL - Gallon'}, 
        { code: 'GRM', description: 'GRM - Gram'},
        { code: 'GRO', description: 'GRO - Gross'},
        { code: 'KG', description: 'KG - Kilogram'},
        { code: 'KTM', description: 'KTM - kilometre'}, 
        { code: 'KWT', description: 'KWT - kilowatt'},
        { code: 'L', description: 'L - Litre'},  
        { code: 'LBR', description: 'LBR - pound'}, 
        { code: 'LK', description: 'LK - link'}, 
        { code: 'LTR', description: 'LTR - Litre'}, 
        { code: 'M', description: 'M - Metre'}, 
        { code: 'M2', description: 'M2 - Square Metre'}, 
        { code: 'M3', description: 'M3 - Cubic Metre '},
        { code: 'MGM', description: 'MGM - milligram'}, 
        { code: 'MTR', description: 'MTR - metre'}, 
        { code: 'MWT', description: 'MWT - megawatt hour (1000 kW.h)'},
        { code: 'NO', description: 'NO - Number'}, 
        { code: 'NX', description: 'NX - part per thousand'},
        { code: 'PA', description: 'PA - packet'},
        { code: 'PG', description: 'PG - plate'}, 
        { code: 'PR', description: 'PR - pair'}, 
        { code: 'RL', description: 'RL - reel'},
        { code: 'RO', description: 'RO - roll'},
        { code: 'SET', description: 'SET - set'},
        { code: 'ST', description: 'ST - sheet'},
        { code: 'TNE', description: 'TNE - tonne (metric ton)'},
        { code: 'TU' , description: 'TU - tube tube'}, 
        { code: 'U', description: 'U - Pieces/item [Number]'}, 
        { code: 'YRD' , description: 'YRD - yard'}
      ],
      etims_item_type_codes:[
        { code: 1, name: 'Raw Material', description: '1 - Raw Material'}, 
        { code: 2, name: 'Finished Product', description: '2 - Finished Product'},
        { code: 3, name: 'Service', description: '3 - Service without stock'}
      ],
      etims_country_codes:[
        // { code: 'AC', description: 'AC - ASCENSION ISLAND'},
        // { code: 'AD', description: 'AD - ANDOKRA'},
        // { code: 'AE', description: 'AE - UNITED ARAB EMIRATES'},
        // { code: 'AG', description: 'AG - ANTIGUA AND BARBUDA'},
        // { code: 'AI', description: 'AI - ANGUILLA'},
        // { code: 'AL', description: 'AL - ALBANIA'},
        // { code: 'AM', description: 'AM - ARMENIA'},
        // { code: 'AN', description: 'AN - NETHERLANDS'},
        // { code: 'AO', description: 'AO - ANGOLA'},
        // { code: 'AQ', description: 'AQ - ANTARCTICA'},
        // { code: 'AR', description: 'AR - ARGENTINA'},
        // { code: 'AS', description: 'AS - AMERICAN SAMOA'},
        // { code: 'AT', description: 'AT - AUSTRIA'},
        // { code: 'AU', description: 'AU - AUSTRALIA'},
        // { code: 'AW', description: 'AW - ARUBA'},
        // { code: 'AX', description: 'AX - ALAND ISLANDS'}, 
        // { code: 'AZ', description: 'AZ - AZERBAIJAN'},
        // { code: 'BA', description: 'BA - BOSNIA AND HERZEGOVINA'},
        // { code: 'BB', description: 'BB - BARBADOS'},
        // { code: 'BD', description: 'BD - BANGLADESH'},
        // { code: 'BE', description: 'BE - BELGIUM'},
        // { code: 'BF', description: 'BF - BURKINA FASO'},
        // { code: 'BG', description: 'BG - BULGARIA'},
        // { code: 'BH', description: 'BH - BAHRAIN'},
        // { code: 'BI', description: 'BI - BURUNDI'},
        // { code: 'BJ', description: 'BJ - BENIN'},
        // { code: 'BM', description: 'BM - BERMUDA'},
        // { code: 'BN', description: 'BN - BRUNEI DARUSSALAM'},
        // { code: 'BO', description: 'BO - BOLIVIA'},
        // { code: 'BR', description: 'BR - BRAZIL'},
        // { code: 'BS', description: 'BS - BAHAMAS'},
        // { code: 'BT', description: 'BT - BHUTAN'},
        // { code: 'BV', description: 'BV - BOUVET ISLAND'},
        // { code: 'BW', description: 'BW - BOTSWANA'},
        // { code: 'BY', description: 'BY - BELARUS'},
        // { code: 'BZ', description: 'BZ - BELIZE'},
        // { code: 'CA', description: 'CA - CANADA'},
        // { code: 'CC', description: 'CC - COCOS (KEELING)ISLANDS'},
        // { code: 'CD', description: 'CD - CONGO, DEMOCRATIC REPUBLIC'},
        // { code: 'CF', description: 'CF - CENTRAL AFRICAN REPUBLIC'},
        // { code: 'CG', description: 'CG - CONGO'},
        // { code: 'CH', description: 'CH - SWITZERLAND'},
        // { code: 'CI', description: 'CI - COTE D'IVOIRE (IVORY COAST)'},
        // { code: 'CK', description: 'CK - COOK ISLANDS'},
        // { code: 'CL', description: 'CL - CHILE'},
        // { code: 'CM', description: 'CM - CAMEROON'},
        // { code: 'CN', description: 'CN - CHINA'},
        // { code: 'CO', description: 'CO - COLOMBIA'},
        // { code: 'CR', description: 'CR - COSTA RICA'},
        // { code: 'CS', description: 'CS - CZECHOSLOVAKIA (FORMER)'},
        // { code: 'CU', description: 'CU - CUBA'},
        // { code: 'CV', description: 'CV - CAPE VERDE'},
        // { code: 'CX', description: 'CX - CHRISTMAS ISLAND'},
        // { code: 'CY', description: 'CY - CYPRUS'},
        // { code: 'CZ', description: 'CZ - CZECH REPUBLIC'},
        // { code: 'DE', description: 'DE - GERMANY'},
        // { code: 'DJ', description: 'DJ - DJIBOUTI'},
        // { code: 'DK', description: 'DK - DENMARK'},
        // { code: 'DM', description: 'DM - DOMINICA'},
        // { code: 'DO', description: 'DO - DOMINICAN REPUBLIC'},
        // { code: 'DZ', description: 'DZ - ALGERIA'},
        // { code: 'EC', description: 'EC - ECUADOR'},
        // { code: 'EE', description: 'EE - ESTONIA'},
        // { code: 'EG', description: 'EG - EGYPT'},
        // { code: 'EH', description: 'EH - WESTERN SAHARA'},
        // { code: 'ER', description: 'ER - ERITREA'},
        // { code: 'ES', description: 'ES - SPAIN'},
        // { code: 'ET', description: 'ET - ETHIOPIA'},
        // { code: 'EU', description: 'EU - EUROPEAN UNION'},
        // { code: 'FI', description: 'FI - FINLAND'},
        // { code: 'FJ', description: 'FJ - FIJI'},
        // { code: 'FK', description: 'FK - FALKLAND ISLANDS(MALVINAS)'},
        // { code: 'FM', description: 'FM - MICRONESIA'},
        // { code: 'FO', description: 'FO - FAROE ISLANDS'},
        // { code: 'FR', description: 'FR - FRANCE'}, 
        // { code: 'FX', description: 'FX - FRANCE METROPOLITAN'},
        // { code: 'GA', description: 'GA - GABON '},
        // { code: 'GB', description: 'GB - GREAT BRITAIN (UK)'},
        // { code: 'GD', description: 'GD - GRENADA'},
        // { code: 'GE', description: 'GE - GEORGIA'},
        // { code: 'GF', description: 'GF - FRENCH GUIANA'},
        // { code: 'GG', description: 'GG - GUERNSEY'},
        // { code: 'GH', description: 'GH - GHANA'},
        // { code: 'GI', description: 'GI - GIBRALTAR'},
        // { code: 'GL', description: 'GL - GREENLAND'},
        // { code: 'GM', description: 'GM - GAMBIA'},
        // { code: 'GN', description: 'GN - GUINEA'},
        // { code: 'GP', description: 'GP - GUADELOUPE'},
        // { code: 'GQ', description: 'GQ - EQUATORIAL GUINEA'},
        // { code: 'GR', description: 'GR - GREECE'}, 
        // { code: 'GS', description: 'GS - S. GEORGIA AND S. SANDWICH ISLS.'}, 
        // { code: 'GT', description: 'GT - GUATEMALA'},
        // { code: 'GU', description: 'GU - GUAM'},
        // { code: 'GW', description: 'GW - GUINEA BISSAU'},
        // { code: 'GY', description: 'GY - GUYANA'},
        // { code: 'HK', description: 'HK - HONG KONG'},
        // { code: 'HM', description: 'HM - HEARD AND MCDONALD ISLANDS'},
        // { code: 'HN', description: 'HN - HONDURAS HONDURAS'},
        // { code: 'HR', description: 'HR - CROATIA (HRVATSKA)'},
        // { code: 'HT', description: 'HT - HAITI'},
        // { code: 'HU', description: 'HU - HUNGARY'},
        // { code: 'ID', description: 'ID - INDONESIA'},
        // { code: 'IE', description: 'IE - IRELAND'}, 
        // { code: 'IL', description: 'IL - ISRAEL ISRAEL'},
        // { code: 'IM', description: 'IM - ISLE OF MAN ISLE OF MAN'},
        // { code: 'IN', description: 'IN - INDIA INDIA'},
        // { code: 'IO', description: 'IO - BRITISH INDIAN OCEAN TERRITORY'},
        // { code: 'IQ', description: 'IQ - IRAQ IRAQ'},
        // { code: 'IR', description: 'IR - IRAN IRAN'},
        // { code: 'IS', description: 'IS - ICELAND'},
        // { code: 'IT', description: 'IT - ITALY'},
        // { code: 'JE', description: 'JE - JERSEY'},
        // { code: 'JM', description: 'JM - JAMAICA'},
        // { code: 'JO', description: 'JO - JORDAN'},
        // { code: 'JP', description: 'JP - JAPAN'},
        { code: 'KE', description: 'KE - KENYA'}
        // { code: 'KG', description: 'KG - KYRGYZSTAN'},
        // { code: 'KH', description: 'KH - CAMBODIA'},
        // { code: 'KI', description: 'KI - KIRIBATI'},
        // { code: 'KM', description: 'KM - COMOROS'},
        // { code: 'KN', description: 'KN - SAINT KITTS AND NEVIS'},
        // { code: 'KP', description: 'KP - NORTH KOREA'},
        // { code: 'KR', description: 'KR - SOUTH KOREA'},
        // { code: 'KW', description: 'KW - KUWAIT'},
        // { code: 'KY', description: 'KY - CAYMAN ISLANDS'},
        // { code: 'KZ', description: 'KZ - KAZAKHSTAN'},
        // { code: 'LA', description: 'LA - LAOS'},
        // { code: 'LB', description: 'LB - LEBANON'},
        // { code: 'LC', description: 'LC - SAINT LUCIA'},
        // { code: 'LI', description: 'LI - LIECHTENSTEIN'},
        // { code: 'LK', description: 'LK - SRI LANKA'},
        // { code: 'LR', description: 'LR - LIBERIA'},
        // { code: 'LS', description: 'LS - LESOTHO'},
        // { code: 'LT', description: 'LT - LITHUANIA'},
        // { code: 'LU', description: 'LU - LUXEMBOURG'},
        // { code: 'LV', description: 'LV - LATVIA'},
        // { code: 'LY', description: 'LY - LIBYA'},
        // { code: 'MA', description: 'MA - MOROCCO'},
        // { code: 'MC', description: 'MC - MONACO'},
        // { code: 'MD', description: 'MD - MOLDOVA'},
        // { code: 'ME', description: 'ME - MONTENEGRO'},
        // { code: 'MG', description: 'MG - MADAGASCAR'},
        // { code: 'MH', description: 'MH - MARSHALL ISLANDS'},
        // { code: 'MK', description: 'MK - F.Y.R.O.M. (MACEDONIA)'},
        // { code: 'ML', description: 'ML - MALI'},
        // { code: 'MM', description: 'MM - MYANMAR'},
        // { code: 'MN', description: 'MN - MONGOLIA'},
        // { code: 'MO', description: 'MO - MACAU'}, 
        // { code: 'MP', description: 'MP - NORTHERN MARIANA ISLANDS'},
        // { code: 'MQ', description: 'MQ - MARTINIQUE'}, 
        // { code: 'MR', description: 'MR - MAURITANIA'},
        // { code: 'MS', description: 'MS - MONTSEKRAT'},
        // { code: 'MT', description: 'MT - MALTA '},
        // { code: 'MU', description: 'MU - MAURITIUS'},
        // { code: 'MV', description: 'MV - MALDIVES'},
        // { code: 'MW', description: 'MW - MALAWI'},
        // { code: 'MX', description: 'MX - MEXICO'},
        // { code: 'MY', description: 'MY - MALAYSIA'},
        // { code: 'MZ', description: 'MZ - MOZAMBIQUE'},
        // { code: 'NA', description: 'NA - NAMIBIA'},
        // { code: 'NC', description: 'NC - NEW CALEDONIA'},
        // { code: 'NE', description: 'NE - NIGER'},
        // { code: 'NF', description: 'NF - NORFOLK ISLAND'},
        // { code: 'NG', description: 'NG - NIGERIA'},
        // { code: 'NI', description: 'NI - NICARAGUA'},
        // { code: 'NL', description: 'NL - NETHERLANDS'},
        // { code: 'NO', description: 'NO - NORWAY'},
        // { code: 'NP', description: 'NP - NEPAL'},
        // { code: 'NR', description: 'NR - NAURU'},
        // { code: 'NT', description: 'NT - NEUTRAL ZONE'},
        // { code: 'NU', description: 'NU - NIUE'},
        // { code: 'NZ', description: 'NZ - NEW ZEALAND (AOTEAROA)'},
        // { code: 'OM', description: 'OM - OMAN'},
        // { code: 'PA', description: 'PA - PANAMA'},
        // { code: 'PE', description: 'PE - PERU'},
        // { code: 'PF', description: 'PF - FRENCH POLYNESIA'},
        // { code: 'PG', description: 'PG - PAPUA NEW GUINEA'},
        // { code: 'PH', description: 'PH - PHILIPPINES'},
        // { code: 'PK', description: 'PK - PAKISTAN'},
        // { code: 'PL', description: 'PL - POLAND'},
        // { code: 'PM', description: 'PM - ST. PIERRE AND MIQUELON'},
        // { code: 'PN', description: 'PN - PITCAIRN'},
        // { code: 'PR', description: 'PR - PUERTO RICO'},
        // { code: 'PS', description: 'PS - PALESTINIAN TERRITORY, OCCUPIED'},
        // { code: 'PT', description: 'PT - PORTUGAL'},
        // { code: 'PW', description: 'PW - PALAU'},
        // { code: 'PY', description: 'PY - PARAGUAY'},
        // { code: 'QA', description: 'QA - QATAR'},
        // { code: 'RE', description: 'RE - REUNION'},
        // { code: 'RO', description: 'RO - ROMANIA'},
        // { code: 'RS', description: 'RS - SERBIA'},
        // { code: 'RU', description: 'RU - RUSSIAN FEDERATION'},
        // { code: 'RW', description: 'RW - RWANDA'},
        // { code: 'SA', description: 'SA - SAUDI ARABIA'},
        // { code: 'SB', description: 'SB - SOLOMON ISLANDS'},
        // { code: 'SC', description: 'SC - SEYCHELLES'},
        // { code: 'SD', description: 'SD - SUDAN'},
        // { code: 'SE', description: 'SE - SWEDEN'},
        // { code: 'SG', description: 'SG - SINGAPORE'},
        // { code: 'SH', description: 'SH - ST. HELENA'},
        // { code: 'SI', description: 'SI - SLOVENIA'},
        // { code: 'SJ', description: 'SJ - SVALBARD & JAN MAYEN ISLANDS'},
        // { code: 'SK', description: 'SK - SLOVAK REPUBLIC'}, 
        // { code: 'SL', description: 'SL - SIEKRA LEONE'},
        // { code: 'SM', description: 'SM - SAN MARINO'},
        // { code: 'SN', description: 'SN - SENEGAL'},
        // { code: 'SO', description: 'SO - SOMALIA'},
        // { code: 'SR', description: 'SR - SURINAME'},
        // { code: 'ST', description: 'ST - SAO TOME AND PRINCIPE'},
        // { code: 'SU', description: 'SU - USSR (FORMER)'},
        // { code: 'SV', description: 'SV - EL SALVADOR'},
        // { code: 'SY', description: 'SY - SYRIA'},
        // { code: 'SZ', description: 'SZ - SWAZILAND'},
        // { code: 'TC', description: 'TC - TURKS AND CAICOSISLANDS'},
        // { code: 'TD', description: 'TD - CHAD'},
        // { code: 'TF', description: 'TF - FRENCH SOUTHERN TERRITORIES '},
        // { code: 'TG', description: 'TG - TOGO'},
        // { code: 'TH', description: 'TH - THAILAND'},
        // { code: 'TJ', description: 'TJ - TAJIKISTAN'},
        // { code: 'TK', description: 'TK - TOKELAU'},
        // { code: 'TM', description: 'TM - TURKMENISTAN'},
        // { code: 'TN', description: 'TN - TUNISIA'},
        // { code: 'TO', description: 'TO 223 TONGA'},
        // { code: 'TP', description: 'TP 224 EAST TIMOR '},
        // { code: 'TR', description: 'TR 225 TURKEY'},
        // { code: 'TT', description: 'TT 226 TRINIDAD AND TOBAGO'},
        // { code: 'TV', description: 'TV 227 TUVALU'},
        // { code: 'TW', description: 'TW 228 TAIWAN'},
        // { code: 'TZ', description: 'TZ 229 TANZANIA'},
        // { code: 'UA', description: 'UA 230 UKRAINE'},
        // { code: 'UG', description: 'UG 231 UGANDA'},
        // { code: 'UK', description: 'UK 232 UNITED KINGDOM'},
        // { code: 'UM', description: 'UM 233 US MINOR OUTLYING ISLANDS'},
        // { code: 'US', description: 'US 234 UNITED STATES'},
        // { code: 'UY', description: 'UY 235 URUGUAY'},
        // { code: 'UZ', description: 'UZ 236 UZBEKISTAN'},
        // { code: 'VA', description: 'VA 237 VATICAN CITY STATE (HOLY SEE)'},
        // { code: 'VC', description: 'VC 238 SAINT VINCENT & THE GRENADINES'},
        // { code: 'VE', description: 'VE 239 VENEZUELA'},
        // { code: 'VG', description: 'VG 240 BRITISH VIRGIN ISLANDS'},
        // { code: 'VI', description: 'VI 241 VIRGIN ISLANDS (U.S.)'},
        // { code: 'VN', description: 'VN 242 VIET NAM'},
        // { code: 'VU', description: 'VU 243 VANUATU'},
        // { code: 'WF', description: 'WF 244 WALLIS AND FUTUNA ISLANDS'},
        // { code: 'WS', description: 'WS 245 SAMOA'},
        // { code: 'YE', description: 'YE 246 YEMEN '},
        // { code: 'YT', description: 'YT 247 MAYOTTE '},
        // { code: 'YU', description: 'YU 248 SERBIA AND MONTENEGRO (FORMER)'},
        // { code: 'ZA', description: 'ZA 249 SOUTH AFRICA'},
        // { code: 'ZM', description: 'ZM 250 ZAMBIA'},
        // { code: 'ZR', description: 'ZR 251 (ZAIRE) SEE CD CONGO, DEMOCRATIC REPUBLIC'},
        // { code: 'ZW', description: 'ZW 252 ZIMBABWE'}, 
      ],
      usedItemCodes: new Set(), // Set to track used invoice numbers

    };
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await this.getAllOrderTypes();
    await this.getGroups();
    await this.getMenuItemSize();
    await this.iseTimsIntergrated();
    if (this.etims.etims_integrated) {
      await this.getEtimsCodes();
      await this.eTimsSelectItem();
      
    }
    this.$store.commit("SET_LOADING", false);
    this.getMenuItems(1);
    
  },

  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    etims() {
      return JSON.parse(localStorage.getItem("etims_integrated"));
    },
    isTimsIntegrated() {
      return this.etims_integrated;
    },
  },
  methods: {
    getCurrentTimestamp() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");

      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const seconds = currentDate.getSeconds().toString().padStart(2, "0");
      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
    getCurrentDateTimestamp() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");
      return `${year}${month}${day}`;
    },
    hideModals() {
      $("#add-modal-close").click();
      $("#edit-modal-close").click();
      $("#import-modal-close").click();
    },
    updateBP(e, item) {
      let sure = confirm(
        "Are you sure you want to update? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        let formData = {
          id: item.id,
          price: e.target.value,
        };
        MenuItemsService.updateBP(formData).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    updateSP(e, item) {
      let sure = confirm(
        "Are you sure you want to update? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        let formData = {
          id: item.id,
          price: e.target.value,
        };
        MenuItemsService.updateSP(formData).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    updateWP(e, item) {
      let sure = confirm(
        "Are you sure you want to update? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        let formData = {
          id: item.id,
          price: e.target.value,
        };
        MenuItemsService.updateWP(formData).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    closeUpdateModal() {
      $("#edit-modal-close").click();
    },
    addLocationReorderQty(e, location) {
      let data = {
        reorder: parseInt(e.target.value),
        location_id: location.id,
      };
      // exist
      let exist = this.location_quantities.find(
        (el) => el.location_id == location.id
      );
      if (exist) {
        this.location_quantities.map((el) => {
          if (el.location_id == location.id) {
            el.reorder = parseInt(e.target.value);
          }
        });
      } else {
        this.location_quantities.push(data);
      }
    },
    addLocationQty(e, location) {
      let data = {
        reorder: 0,
        quantity: parseInt(e.target.value),
        location_id: location.id,
      };
      // exist
      let exist = this.location_quantities.find(
        (el) => el.location_id == location.id
      );
      if (exist) {
        this.location_quantities.map((el) => {
          if (el.location_id == location.id) {
            el.quantity = parseInt(e.target.value);
          }
        });
      } else {
        this.location_quantities.push(data);
      }
      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    addLocationQtyInit(location) {
      let data = {
        reorder: 0,
        quantity: parseInt(0),
        location_id: location.id,
      };
      // exist
      let exist = this.location_quantities.find(
        (el) => el.location_id == location.id
      );
      if (exist) {
        this.location_quantities.map((el) => {
          if (el.location_id == location.id) {
            el.quantity = parseInt(0);
          }
        });
      } else {
        this.location_quantities.push(data);
      }
      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    myCallback(page) {
      this.page = page;
      this.getMenuItems(page);
    },
    async uploadData() {
      this.$store.commit("SET_LOADING", true);
      let excelData = await this.import_resources.map((element) => {
        if (element[0]) {
          return {
            name: element[0],
            barcode: element[1],
            description: element[2],
            buy_price: element[3],
            price: element[4],
            ws_price: element[5],
            stock_amount: element[6],
            unit_name: element[7],
            category: element[8],
            vat: element[9] == true ? 1 : 0,
          };
        }
      });
      let jsonData = JSON.stringify(excelData);
      let formData = {
        location: this.order_type,
        excel_data: jsonData,
      };
      await MenuItemsService.import(formData).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data);
            this.page = 1;
            this.getMenuItems(1);
            return response.data.data;
          } else {
            return [];
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          return [];
        }
      );
      toast.success("Items Uploaded successfully!");
      this.$store.commit("SET_LOADING", false);
    },
    importExcel() {
      const input = document.getElementById("import-excel");
      readXlsFile(input.files[0]).then((rows) => {
        this.import_resources = rows.filter(
          (item, index) => index != 0 && item[1]
        );
      });
    },
    async exportData() {
      this.$store.commit("SET_LOADING", true);
      const data = await MenuItemsService.all(this.order_type).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data);
            return response.data.data;
          } else {
            return [];
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          return [];
        }
      );
      const fileName = "BullaPOs Expoorted Products";
      const exportType = exportXlsFile.types.xls; // xls
      exportXlsFile({ data, fileName, exportType });
      this.$store.commit("SET_LOADING", false);
    },
    searchItem() {
      this.page = 1;
      this.current_page = 1;
      let formData = {
        search_term: this.search_term,
      };
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.menuSearch(formData, this.current_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    goToPOS() {
      this.$router.push("/pos");
    },
    goToBarcode() {
      this.$router.push("/barcode");
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    addNew() {
      this.v$.formData.$reset();
      this.formData = {
        group_id: "",
        active: 0,
        visible: 1,
        barcode: "",
        unit_name: "",
        description: "",
        order_types: [],
      };
      this.file = "";
      this.filename = "";
      this.show_location_error = false;
      this.formData.selected_order_types = [];
    },
    editItem(item) {
      this.formData = item;
      if (item.locations) {
        this.location_quantities = item.locations.map((el) => {
          return {
            reorder: el.pivot.reorder,
            quantity: el.pivot.quantity,
            location_id: el.pivot.order_type_id,
          };
        });
      } else {
        this.location_quantities = [];
        this.formData.order_types = [];
      }
      if (item.order_types?.length > 0) {
        this.formData.selected_order_types = this.order_types.filter((el) =>
          item.order_types.includes(el.id)
        );
      } else {
        this.formData.selected_order_types = [];
      }
      this.show_location_error = false;
    },

    addToLocations(location) {
      if (this.formData.order_types.length > 0) {
        if (
          this.formData.order_types &&
          this.formData.order_types.includes(location.id)
        ) {
          this.formData.order_types = this.formData.order_types.filter(
            (el) => el != location.id
          );
          this.formData.selected_order_types =
            this.formData.selected_order_types.filter(
              (el) => el.id != location.id
            );
          this.location_quantities = this.location_quantities.filter(
            (el) => el.location_id != location.id
          );
        } else {
          if (this.formData.order_types) {
            this.formData.order_types.push(location.id);
          } else {
            this.formData.order_types = [];
            this.formData.order_types.push(location.id);
          }
          this.formData.selected_order_types.push(location);
        }
      } else {
        this.formData.order_types = [];
        this.formData.order_types.push(location.id);
        this.formData.selected_order_types.push(location);
      }

      this.formData.STOCK_AMOUNT = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
     this. addLocationQtyInit(location);
    },
    onFileChange(e) {
      console.log(e.target.files[0]);
      this.filename = "Selected File: " + e.target.files[0].name;
      this.file = e.target.files[0];
    },

    async selectOrderType(e) {
      this.order_type = e.target.value;
      await this.getMenuItems(this.page);
      this.$store.commit("SET_LOADING", false);
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    sortItems(list) {
      return list.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getItems() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getMenuItems(page) {
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.byOrder(page, this.order_type).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    groupSelected(e) {
      this.formData.GROUP_ID = e.target.value;
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            console.log("AUTO GRP SELECT", response.data.data.at(0).id);
            this.formData.GROUP_ID = response.data.data.at(0).id;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      console.log(this.formData);
      this.formData.visible = !this.formData.visible;
    },
    toggleVAT() {
      this.formData.vat = !this.formData.vat;
    },
    toggleSellable() {
      this.formData.sellable = !this.formData.sellable;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    generateItemCode() {
      let newNumber;
      // Generate a unique 8-digit number
      do {
        newNumber = Math.floor(10000000 + Math.random() * 90000000);
      } while (this.usedItemCodes.has(newNumber));
      // Store the generated number to avoid duplication
      this.usedItemCodes.add(newNumber);

      const classificationPrefix = 'KE';
      const productType = this.formData.itemTyCd || '2NT';
      const packagingUnit = this.formData.pkgUnitCd || 'BA';
      // Set the invoiceNumber data property

      return `${classificationPrefix}${productType}${packagingUnit}${newNumber}`;
    },

    saveResource() {
      this.v$.formData.$touch();
      if (this.formData.order_types.length == 0) {
        this.show_location_error = true;
        return;
      }
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("order_types", this.formData.order_types);
      formData.append("name", this.formData.name);
      formData.append("price", this.formData.price);
      formData.append("ws_price", this.formData.ws_price ? this.formData.ws_price : 0);
      formData.append("offer_price", this.formData.offer_price ? this.formData.offer_price : 0);
      formData.append("barcode", this.formData.barcode);
      formData.append("group_id", this.formData.group_id);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("description", this.formData.description);
      formData.append("stock_amount", this.formData.stock_amount);
      formData.append("vat", this.formData.vat ? 1 : 0);
      formData.append("sellable", this.formData.sellable ? 1 : 0);
      formData.append("visible", this.formData.visible ? 1 : 0);
      formData.append(
        "location_quantities",
        JSON.stringify(this.location_quantities)
      );

      if (this.etims.etims_integrated) { 
        
        const itemCd = this.generateItemCode(); //'KE2NTBA0000012'

        //ClasificationPrefix + productType.toString() + packagingUnit.toString() + valuIncreaments;

        formData.append("tin", this.etims.tin);
        formData.append("bhfId", this.etims.bhfId);
        formData.append("itemClsCd", this.formData.itemClsCd); // Item Code
        formData.append("itemCd", itemCd); // Item Classification Code
        formData.append("itemTyCd", this.formData.itemTyCd); // item Type Code
        formData.append("itemNm", this.formData.itemNm); // item Name
        formData.append("orgnNatCd", this.formData.orgnNatCd); // Origin Place Code (Nation)
        formData.append("pkgUnitCd", this.formData.pkgUnitCd); //  Packaging Unit Code
        formData.append("qtyUnitCd", this.formData.qtyUnitCd); // Quantity Unit Code
        formData.append("taxTyCd", this.formData.taxTyCd); // Taxation Type Code
        formData.append("dftPrc", this.formData.dftPrc); // Default Unit Price
        formData.append("isrcAplcbYn", this.formData.isrcAplcbYn); //Insurance Appicable(Y/N)
        formData.append("useYn", this.formData.useYn); //  Yes/No
        formData.append("regrNm", this.formData.regrNm); // Registrant Name
        formData.append("regrId", this.formData.regrId); // Registrant ID
        formData.append("modrNm", this.formData.modrNm); // Modifier Name
        formData.append("modrId", this.formData.modrId); // Modifier ID

        console.log("ALL GOOD ETIMS", formData)

        //run post request for etims saveItems
        EtimsService.saveItems(this.formData, config).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.resultMsg, {
                autoClose: 2000,
              });
            } else {
              toast.error(
                response.data.error || response.data.errors.at(0) || response.data.resultMsg
              );
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      MenuItemsService.store(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            // Clear modals
            this.page = 1;
            this.current_page = 1;
            this.getMenuItems(this.page);
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.closeUpdateModal();
            this.hideModals();
          } else {
            console.log(response);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
            this.closeUpdateModal();
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.v$.formData.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      if (this.formData.order_types.length == 0) {
        this.show_location_error = true;
        return;
      }

      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("id", this.formData.id);
      formData.append("order_types", this.formData.order_types);
      formData.append("name", this.formData.name);
      formData.append("price", this.formData.price);
      formData.append("ws_price", this.formData.ws_price ? this.formData.ws_price : 0);
      formData.append("offer_price", this.formData.offer_price ? this.formData.offer_price : 0);
      formData.append("barcode", this.formData.barcode);
      formData.append("group_id", this.formData.group_id);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("description", this.formData.description);
      formData.append("stock_amount", this.formData.stock_amount);
      formData.append("vat", this.formData.vat ? 1 : 0);
      formData.append("sellable", this.formData.sellable ? 1 : 0);
      formData.append("visible", this.formData.visible ? 1 : 0);
      formData.append(
        "location_quantities",
        JSON.stringify(this.location_quantities)
      );

      console.log("UPDATE ME HERE");
      if (this.etims.etims_integrated) {   
        
        const itemCd = this.generateItemCode(); //'KE2NTBA0000012'

        formData.append("tin", this.etims.tin);
        formData.append("bhfId", this.etims.bhfId);
        formData.append("itemClsCd", this.formData.itemClsCd); // Item Code
        formData.append("itemCd", itemCd); // Item Classification Code
        formData.append("itemTyCd", this.formData.itemTyCd); // item Type Code
        formData.append("itemNm", this.formData.itemNm); // item Name
        formData.append("orgnNatCd", this.formData.orgnNatCd); // Origin Place Code (Nation)
        formData.append("pkgUnitCd", this.formData.pkgUnitCd); //  Packaging Unit Code
        formData.append("qtyUnitCd", this.formData.qtyUnitCd); // Quantity Unit Code
        formData.append("taxTyCd", this.formData.taxTyCd); // Taxation Type Code
        formData.append("dftPrc", this.formData.dftPrc); // Default Unit Price
        formData.append("isrcAplcbYn", this.formData.isrcAplcbYn); //Insurance Appicable(Y/N)
        formData.append("useYn", this.formData.useYn); //  Yes/No
        formData.append("regrNm", this.formData.regrNm); // Registrant Name
        formData.append("regrId", this.formData.regrId); // Registrant ID
        formData.append("modrNm", this.formData.modrNm); // Modifier Name
        formData.append("modrId", this.formData.modrId); // Modifier ID

        console.log(this.formData);
        //run post request for etims saveItems
        EtimsService.saveItems(this.formData, config).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.resultMsg, {
                autoClose: 2000,
              });
            } else {
              toast.error(
                response.data.error || response.data.errors.at(0) || response.data.resultMsg
              );
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );

        const prefix = 'ITM';
        const randomNumber = Math.floor(Math.random() * 900) + 100; // Generates a random number between 100 and 999
        const cpstItemCd = `${prefix}${randomNumber}`;
        const cpstQty = this.formData.cpstQty;
        const regrId = this.formData.regrId;
        const regrNm = this.formData.regrNm;

        // {"tin":"P123456789Z","bhfId":"00","lastReqDt":"20160523000000"}
        let lastReqDt = this.getCurrentTimestamp();


        // etims item composition
        // note: check more info on cpstItemCd and cpstQty
        if (this.formData.cpstQty > 0 ) {
          const { tin, bhfId } = this.etims;

          return EtimsService.saveItemComposition({ tin, bhfId, lastReqDt, cpstQty, cpstItemCd, regrId ,regrNm}).then(
            (response) => {
              if (response.data.code == 200) {
                toast.success(response.data.resultMsg, {
                  autoClose: 2000,
                });
              } else {
                toast.error(
                  response.data.error || response.data.errors.at(0) || response.data.resultMsg
                );
              }
              this.$store.commit("SET_LOADING", false);
            },
            (error) => {
              console.log(error);
              this.$store.commit("SET_LOADING", false);
            }
          );
        }
      }


      MenuItemsService.update2(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getMenuItems(this.page);
            this.closeUpdateModal();
            this.hideModals();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
            this.closeUpdateModal();
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );


    },
    deleteResource(resource) {
      console.log(resource);
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        MenuItemsService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
              this.getMenuItems(this.page);
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.index().then(
        (response) => {
          console.log("getdata");
          console.group(response);
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    iseTimsIntergrated(){
      this.etims_integrated = this.etims.etims_integrated;
    },

    getEtimsCodes() {
      this.$store.commit("SET_LOADING", true);

      if (this.etims.etims_integrated) {
        let lastReqDt = this.getCurrentTimestamp();

        const { tin, bhfId } = this.etims;

        return EtimsService.getcodelist({ tin, bhfId, lastReqDt }).then(
          (response) => {
            if (response.data.code == 200) {
              this.etims_code_list = response.data.data;
              console.log(response.data.data);
            } else {
              toast.error(
                response.data.error || response.data.errors.at(0) || response.data.resultMsg
              );
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },


    eTimsSelectItem(){
      this.$store.commit("SET_LOADING", true);

      if (this.etims.etims_integrated) {
        let lastReqDt = this.getCurrentTimestamp();

        const { tin, bhfId } = this.etims;

        return EtimsService.selectItems({ tin, bhfId, lastReqDt }).then(
          (response) => {
            if (response.data.code == 200) {
              toast.success(response.data.resultMsg, {
                autoClose: 2000,
              });
            } else {
              toast.error(
                response.data.error || response.data.errors.at(0) || response.data.resultMsg
              );
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    }
  },
};
</script>
