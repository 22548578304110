<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-users"></i>
              Users
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage all available system users
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div class="d-flex">
              <button
                class="btn btn-sm btn-falcon-primary ms-2"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
                @click="addItem"
              >
                <span class="fas fa-user-plus" data-fa-transform=""></span>
              </button>
              <button
                @click="forceReload"
                class="btn btn-sm btn-falcon-primary ms-2"
              >
                <i class="fa-solid fa-arrows-rotate"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable
          id="tour-step-1"
          table-class-name="customize-table"
          alternating
          buttons-pagination
          :headers="headers"
          :items="resources"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
        >
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button
                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="order-dropdown-0"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div class="py-2">
                  <a
                    class="dropdown-item"
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-modal"
                    @click="editItem(item)"
                    >Edit</a
                  >

                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    role="button"
                    @click="deleteRecource(item)"
                    >Delete</a
                  >
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="
                btn-close btn btn-sm btn-circle
                d-flex
                flex-center
                transition-base
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add user</h4>
            </div>
            <div class="px-4 py-2">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label">First Name:</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g John"
                    v-model="formData.first_name"
                    :class="
                      v$.formData.first_name.$dirty &&
                      v$.formData.first_name.$invalid
                        ? `is-invalid`
                        : v$.formData.first_name.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.first_name.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  <label class="col-form-label">Last Name</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g Doe"
                    v-model="formData.last_name"
                    :class="
                      v$.formData.last_name.$dirty &&
                      v$.formData.last_name.$invalid
                        ? `is-invalid`
                        : v$.formData.last_name.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.last_name.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label">Phone</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g 0712xxxx"
                    v-model="formData.phone_no"
                    :class="
                      v$.formData.phone_no.$dirty &&
                      v$.formData.phone_no.$invalid
                        ? `is-invalid`
                        : v$.formData.phone_no.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.phone_no.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label" for="message-text"
                    >User Type</label
                  >
                  <select
                    class="form-select"
                    placeholder="Select parent"
                    v-model="formData.role"
                    :class="
                      v$.formData.role.$dirty && v$.formData.role.$invalid
                        ? `is-invalid`
                        : v$.formData.role.$dirty
                        ? `is-valid`
                        : ``
                    "
                  >
                    <option value="">Select role...</option>
                    <option
                      v-for="mod in user_roles"
                      :key="mod.id"
                      :value="mod.id"
                    >
                      {{ mod.title }}
                    </option>
                  </select>
                  <div
                    v-for="error of v$.formData.role.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label" for="message-text">Email</label>

                  <input
                    class="form-control"
                    type="text"
                    v-model="formData.email"
                    :class="
                      v$.formData.email.$dirty && v$.formData.email.$invalid
                        ? `is-invalid`
                        : v$.formData.email.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.email.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label" for="message-text"
                    >Password</label
                  >

                  <input
                    class="form-control"
                    type="password"
                    v-model="formData.password"
                    :class="
                      v$.formData.password.$dirty &&
                      v$.formData.password.$invalid
                        ? `is-invalid`
                        : v$.formData.password.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.password.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label" for="message-text"
                    >Active</label
                  >
                  <select
                    class="form-select"
                    name="active"
                    id="active-input"
                    v-model="formData.active"
                    :class="
                      v$.formData.active.$dirty && v$.formData.active.$invalid
                        ? `is-invalid`
                        : v$.formData.active.$dirty
                        ? `is-valid`
                        : ``
                    "
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                  <div
                    v-for="error of v$.formData.active.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary"
              role="button"
              @click="saveResource"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->

    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="edit-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="
                btn-close btn btn-sm btn-circle
                d-flex
                flex-center
                transition-base
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add user</h4>
            </div>
            <div class="px-4 py-2">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label">First Name:</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g John"
                    v-model="formData.first_name"
                    :class="
                      v$.formData.first_name.$dirty &&
                      v$.formData.first_name.$invalid
                        ? `is-invalid`
                        : v$.formData.first_name.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.first_name.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-6">
                  <label class="col-form-label">Last Name</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g Doe"
                    v-model="formData.last_name"
                    :class="
                      v$.formData.last_name.$dirty &&
                      v$.formData.last_name.$invalid
                        ? `is-invalid`
                        : v$.formData.last_name.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.last_name.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label">Phone</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="e.g 0712xxxx"
                    v-model="formData.phone_no"
                    :class="
                      v$.formData.phone_no.$dirty &&
                      v$.formData.phone_no.$invalid
                        ? `is-invalid`
                        : v$.formData.phone_no.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.phone_no.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label" for="message-text"
                    >User Type</label
                  >
                  <select
                    class="form-select"
                    placeholder="Select parent"
                    v-model="formData.role"
                    :class="
                      v$.formData.role.$dirty && v$.formData.role.$invalid
                        ? `is-invalid`
                        : v$.formData.role.$dirty
                        ? `is-valid`
                        : ``
                    "
                  >
                    <option value="">Select role</option>
                    <option
                      v-for="mod in user_roles"
                      :key="mod.id"
                      :value="mod.id"
                    >
                      {{ mod.title }}
                    </option>
                  </select>
                  <div
                    v-for="error of v$.formData.role.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label" for="message-text">Email</label>

                  <input
                    class="form-control"
                    type="text"
                    v-model="formData.email"
                    :class="
                      v$.formData.email.$dirty && v$.formData.email.$invalid
                        ? `is-invalid`
                        : v$.formData.email.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.email.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label" for="message-text"
                    >Password</label
                  >

                  <input
                    class="form-control"
                    type="password"
                    v-model="formData.password"
                    :class="
                      v$.formData.password.$dirty &&
                      v$.formData.password.$invalid
                        ? `is-invalid`
                        : v$.formData.password.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.password.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <label class="col-form-label" for="message-text"
                    >Active</label
                  >
                  <select
                    class="form-select"
                    name="active"
                    id="active-input"
                    v-model="formData.active"
                    :class="
                      v$.formData.active.$dirty && v$.formData.active.$invalid
                        ? `is-invalid`
                        : v$.formData.active.$dirty
                        ? `is-valid`
                        : ``
                    "
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                  <div
                    v-for="error of v$.formData.active.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    This field is invalid
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary"
              role="button"
              @click="updateResource"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import AppBar from "@/components/AppBar.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import mixins from "../mixins/index";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import UserService from "../services/user.service";
import RoleService from "../services/role.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import EasyDataTable from "vue3-easy-data-table";

const now = new Date();

export default {
  mixins: [mixins],
  name: "TicketAnalysisReportView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        role: { required },
        email: { required, email },
        password: { required },
        phone_no: { required },
        last_name: { required },
        first_name: { required },
        active: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      searchValue: "",
      table_keys: 1,
      formData: {
        role: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        active: 0,
      },
      resources: [],
      user_roles: [],
      orders_table_key: 0,
      searchField: ["first_name", "last_name", "email", "auto_id"],
      headers: [
        { text: "#", value: "auto_id", sortable: true, width: 50 },
        { text: "First Name", value: "first_name", sortable: true },
        { text: "Last Name", value: "last_name", sortable: true },
        { text: "Phone No", value: "phone_no", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Role", value: "role_name", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    this.getData();
    this.getRoles();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.searchValue = "";
  },
  methods: {
    addItem() {
      this.formData = {
        role: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        active: 1,
      };
    },
    editItem(item) {
      this.formData = item;
    },
    getRoles() {
      this.$store.commit("SET_LOADING", true);
      return RoleService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.user_roles = response.data.data;
          } else {
            this.user_roles = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      if (this.v$.formData.$invalid) {
        return;
      }
      this.formData.name =
        this.formData.first_name + " " + this.formData.last_name;
      this.$store.commit("SET_LOADING", true);
      UserService.store(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.$store.commit("SET_LOADING", true);
      this.formData.name =
        this.formData.FIRST_NAME + " " + this.formData.last_name;
      UserService.update(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      this.$store.commit("SET_LOADING", true);
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        UserService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return UserService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
