// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class MenuItemSizeService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'menu-item-size', { headers: authHeader() })
    }
    store(item) {
        return axios.post(VUE_APP_BASE_URL + 'menu-item-size', item, { headers: authHeader() })
    }
    update(item) {
        return axios.put(VUE_APP_BASE_URL + 'menu-item-size/' + item.id, item, { headers: authHeader() })
    }
    delete(item) {
        return axios.delete(VUE_APP_BASE_URL + 'menu-item-size/' + item.id, { headers: authHeader() })
    }
    
}

export default new MenuItemSizeService()