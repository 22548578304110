import AuthService from "../services/auth.service";

const initialState = {
  table: "",
  cart: [],
  user: "",
};
export const pos = {
  namespaced: true,
  state: initialState,
  actions: {
    setTable({ commit }, table) {
      commit("setTable", table);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    updateCart({ commit }, item) {
      commit("updateCart", item);
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
  },
  mutations: {
    // handler: () => { },
    setTable(state, table) {
      state.table = table;
    },
    setUser(state, user) {
      state.user = user;
    },
    updateCart(state, cart) {
      state.cart = cart;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.snackbar.show = false;
      state.snackbar.color = "";
      state.snackbar.message = "";
    },
  },
  getters: {
    user: (state) => state.user,
  },
};
