<template>
  <div>
    <!-- Control Section -->
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <!-- Date and Filter Controls -->
          <div class="col-2">
            <button class="btn btn-falcon-primary btn-sm" type="button" @click="setToday">
              Today
            </button>
          </div>
          <div class="col-7">
            <select class="form-select d-inline me-2" aria-label="Select order type" v-model="formData.order_type" @change="fetchReportData" style="width: 30%">
              <option value="0">All locations</option>
              <option v-for="(item, i) in orderTypes" :key="i" :value="item.id">{{ item.name }}</option>
            </select>
            <flat-pickr v-model="formData.from" @change="fetchReportData" class="form-control d-inline" placeholder="Start Date" :config="dateConfig" style="width: 30%" />
            To
            <flat-pickr v-model="formData.to" @change="fetchReportData" class="form-control d-inline ml-2" placeholder="End Date" :config="dateConfig" style="width: 30%" />
          </div>

          <!-- Export Controls -->
          <div class="col-3 d-flex">
            <download-excel :data="resources" name="sales-by-category-report">
              <button class="btn btn-falcon-primary btn-sm me-1 mb-2 d-flex" type="button">
                <span class="fas fa-file-excel m-1"></span> Excel
              </button>
            </download-excel>
            <button class="btn btn-falcon-info btn-sm me-1 mb-2 d-flex" type="button" @click="generatePdfReport">
              <span class="fas fa-file-pdf m-1"></span> PDF
            </button>
            <button class="btn btn-falcon-default btn-sm me-1 mb-2 d-flex" type="button" @click="printReport">
              <span class="fas fa-print m-1"></span> Print
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Report Section -->
    <div class="card mb-3">
      <div class="card-body">
        <!-- Report Header -->
        <div class="row text-center mb-3">
          <div class="col">
            <h4>Sales By Category Report</h4>
            <h6>{{ user.company.name }}, {{ user.company.address_line1 }}</h6>
            <p class="fs--1 mb-0">{{ user.company.address_line2 }}</p>
            <p class="fs--1 mb-0">{{ user.company.address_line3 }}</p>
            <p class="fs--1 mb-0">Date: {{ formData.from }} To: {{ formData.to }}</p>
          </div>
        </div>

        <!-- Report Table -->
        <div class="table-responsive scrollbar">
          <table class="table table-striped" v-for="(category, index) in resources" :key="index">
            <tbody>
              <tr>
                <td colspan="4" class="bg-primary text-white">{{ category[0] }}</td>
              </tr>
              <tr>
                <td colspan="4">
                  <table class="table table-striped mt-n3">
                    <thead class="bg-light">
                      <tr>
                        <th>NAME</th>
                        <th>PRICE</th>
                        <th class="text-end">QUANTITY</th>
                        <th class="text-end">AMOUNT (KSH)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in category[1].records" :key="i">
                        <td>{{ item.name }}</td>
                        <td>{{ item.price }}</td>
                        <td class="text-end">{{ item.quantity }}</td>
                        <td class="text-end">{{ formatToCurrency(item.total) }}</td>
                      </tr>
                      <tr class="fw-bolder text-900">
                        <th colspan="3">Total Amount:</th>
                        <td class="text-end">{{ formatToCurrency(category[1].summary) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Report Footer -->
      <div class="card-footer bg-light">
        <p class="fs--1 mb-0"><strong>Report by: </strong>Bulla POS Restaurant</p>
      </div>
    </div>

    <!-- PDF Generation -->
    <Vue3Html2pdf
      :key="printarea_key"
      :show-layout="false"
      :enable-download="enable_download"
      :preview-modal="preview_modal"
      :paginate-elements-by-height="1100"
      filename="Sales by Category Report"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      @progress="onProgress"
    >
      <template v-slot:pdf-content>
        <div class="pdf-item card mb-3">
            <div class="card-body">
                <!-- Report Header -->
                <div class="row text-center mb-3">
                    <div class="col">
                        <h4>Sales By Category Report</h4>
                        <h6>{{ user.company.name }}, {{ user.company.address_line1 }}</h6>
                        <p class="fs--1 mb-0">{{ user.company.address_line2 }}</p>
                        <p class="fs--1 mb-0">{{ user.company.address_line3 }}</p>
                        <p class="fs--1 mb-0">Date: {{ formData.from }} To: {{ formData.to }}</p>
                    </div>
                </div>
                <!-- Report Table -->
                <div class="table-responsive scrollbar">
                    <table class="table table-striped" v-for="(category, index) in resources" :key="index">
                        <tbody>
                        <tr>
                            <td colspan="4" class="bg-primary text-white">{{ category[0] }}</td>
                        </tr>
                        <tr>
                            <td colspan="4">
                            <table class="table table-striped mt-n3">
                                <thead class="bg-light">
                                <tr>
                                    <th>NAME</th>
                                    <th>PRICE</th>
                                    <th class="text-end">QUANTITY</th>
                                    <th class="text-end">AMOUNT (KSH)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, i) in category[1].records" :key="i">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.price }}</td>
                                    <td class="text-end">{{ item.quantity }}</td>
                                    <td class="text-end">{{ formatToCurrency(item.total) }}</td>
                                </tr>
                                <tr class="fw-bolder text-900">
                                    <th colspan="3">Total Amount:</th>
                                    <td class="text-end">{{ formatToCurrency(category[1].summary) }}</td>
                                </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Report Footer -->
            <div class="card-footer bg-light">
                <p class="fs--1 mb-0"><strong>Report by: </strong>Bulla POS Restaurant</p>
            </div>
        </div>
      </template>
    </Vue3Html2pdf>
  </div>
</template>

<script>
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OrderTypeService from "../services/order_types.service";
import ReportService from "../services/report.service";
import Vue3Html2pdf from 'vue3-html2pdf';

export default {
  name: "SalesReport",
  components: { flatPickr, Vue3Html2pdf },
  data() {
    return {
      formData: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        order_type: 0,
      },
      orderTypes: [],
      resources: [],
      summary: 0,
      user: {},
      printarea_key: 1,
      enable_download: false,
      dateConfig: { dateFormat: "Y-m-d" },
    };
  },
  created() {
    this.loadUserData();
    this.fetchOrderTypes();
    this.fetchReportData();
  },
  methods: {
    async fetchReportData() {
      try {
        const response = await ReportService.salesAnalysisByCategory(this.formData);
        if (response.data.code === 200) {
          this.resources = Object.entries(response.data.data);
        } else {
          console.error(response.data.error);
        }
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    },
    async fetchOrderTypes() {
      try {
        const response = await OrderTypeService.index();
        if (response.data.code === 200) {
          this.orderTypes = response.data.data;
        } else {
          console.error(response.data.error);
        }
      } catch (error) {
        console.error("Error fetching order types:", error);
      }
    },
    setToday() {
      this.formData.from = moment().format("YYYY-MM-DD");
      this.formData.to = moment().format("YYYY-MM-DD");
      this.fetchReportData();
    },
    loadUserData() {
      const user = localStorage.getItem("user");
      this.user = user ? JSON.parse(user) : this.logout();
    },
    formatToCurrency(amount) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "KES",
      }).format(amount);
    },
    generatePdfReport() {
        this.$store.commit("SET_LOADING", true);
        this.enable_download = true;
        this.printarea_key += 1;
        //   this.$refs.html2Pdf.generatePdf();
        setTimeout(() => {
            this.$refs.html2Pdf.generatePdf();
            this.$store.commit("SET_LOADING", false);
        }, 1000);
    },
    printReport() {
        this.$store.commit("SET_LOADING", true);
        this.enable_download = false;
        this.preview_modal = true;
        this.printarea_key += 1;
        this.report_name = "Sales By Category Report";
        // this.$refs.html2Pdf.generatePdf();
        setTimeout(() => {
            this.$refs.html2Pdf.generatePdf();
            this.$store.commit("SET_LOADING", false);
        }, 1000);
    },
  },
};
</script>
