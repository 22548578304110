// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class WithdrawalService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'withdrawal', { headers: authHeader() })
    }
    store(resource) {
        return axios.post(VUE_APP_BASE_URL + 'withdrawal', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + 'withdrawal/' + resource.id, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(VUE_APP_BASE_URL + 'withdrawal/' + resource.id, { headers: authHeader() })
    }
    storeSingle(resource) {
        return axios.post(VUE_APP_BASE_URL + 'withdrawal', resource, { headers: authHeader() })
    }
}

export default new WithdrawalService()